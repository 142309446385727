import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
	colors: {
		transparent: 'transparent',
		black: '#000',
		white: '#fff',
		mainBg: '#F5F5F5',
		primary: {
			50: '#EFF3FF',
			100: '#BDC9FA',
			200: '#93A6F6',
			300: '#6883F3',
			400: '#3E60EF',
			500: '#3A5DEF',
			600: '#0B258E',
			700: '#0B258E',
			800: '#08185E',
			900: '#040C2F',
		},
		secondary: {
			50: '#EAEAEA',
			100: '#DBDBDB',
			200: '#C4C4C4',
			300: '#ADADAD',
			400: '#969696',
			500: '#2E2E2E',
			600: '#292929',
			700: '#202020',
			800: '#171717',
			900: '#050505',
		},
		gray: {
			50: '#F4F4F4',
			100: '#DBDBDB',
			200: '#C4C4C4',
			300: '#ADADAD',
			400: '#969696',
			500: '#808080',
			600: '#666666',
			700: '#4D4D4D',
			800: '#333333',
			900: '#1A1A1A',
		},
	},

	fonts: {
		body: 'Lato, sans-serif',
		heading: 'Montserrat, sans-serif',
	},

	fontSizes: {
		xs: '0.75rem',
		sm: '0.875rem', //taken
		md: '1rem',
		lg: '1.1rem',
		xl: '1.125rem',
		'2xl': '1.2rem',
		'3xl': '1.375rem',
		'4xl': '1.75rem', //taken
		'5xl': '2rem', //taken
		'6xl': '2.25rem',
		'7xl': '4rem',
		'8xl': '5.5rem',
		'9xl': '7.3rem',
	},

	radii: {
		none: '0',
		sm: '0.125rem',
		base: '0.25rem',
		md: '0.375rem',
		lg: '0.5rem',
		xl: '0.75rem',
		'2xl': '1rem',
		'3xl': '1.5rem',
		full: '9999px',
	},

	shadows: {
		primary:
			'0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);',
		light: '0px 1px 3px 1px rgba(0, 0, 0, 0.15);',
	},

	sizes: {
		container: {
			sm: '640px',
			md: '768px',
			lg: '1024px',
			xl: '1280px',
		},
	},
});

export default theme;

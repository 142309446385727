import {
	Box,
	Button,
	CloseButton,
	Flex,
	FormControl,
	Heading,
	Input,
	Text,
} from '@chakra-ui/react';
import React, { useContext, useRef, useState, useEffect } from 'react';
import {
	MdLabelImportantOutline,
	MdOutlineTextsms,
	MdTitle,
} from 'react-icons/md';
import { RichTextEditor, SectionHeadings } from '../../components';

import { AuthContext } from '../../context/Auth.Context';
import { DashboardContext } from '../../context/DashboardContext';
import { MainLayout, SidebarLayout } from '../../layouts';
import {
	getServiceImageUrl,
	sendImage,
	getServiceById,
} from '../../services/requests';
import LatestFromUs from '../Dashboard/components/LatestFromUs';
import { useNavigate, useParams } from 'react-router-dom';

const CreateService = () => {
	const [name, setName] = useState('');
	const [slug, setSlug] = useState('');
	const [description, setDescription] = useState('');
	const [coverImage, setCoverImage] = useState('');
	const [content, setContent] = useState('');
	const [status, setStatus] = useState('');
	//const [images, setImages] = useState([]);
	const [metaTitle, setMetaTitle] = useState('');
	const [metaDescription, setMetaDescription] = useState('');
	const [coverImageDisplayUrl, setCoverImageDisplayUrl] = useState('');
	const { currentProject } = useContext(AuthContext);
	const { updateService } = useContext(DashboardContext);
	const [serviceCategoryId, setServiceCategoryId] = useState('');
	const navigate = useNavigate();
	const params = useParams();

	const serviceId = params.id;

	const selectCoverImageInputRef = useRef(null);

	useEffect(() => {
		getService().then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentProject]);

	const getService = async () => {
		const uniqueToken = currentProject?.unique_token;
		if (!!uniqueToken) {
			const res = await getServiceById(uniqueToken, serviceId);
			setName(res?.data?.name || '');
			setSlug(res?.data?.serviceSlug || '');
			setDescription(res?.data?.serviceDescription);
			setCoverImage(res?.data?.serviceCoverImage || '');
			setCoverImageDisplayUrl(res?.data?.serviceCoverImage || '');
			setMetaTitle(res?.data?.metaTitle);
			setMetaDescription(res?.data?.metaDescription || '');
			setContent(res?.data?.serviceContent);
			setStatus(res?.data?.serviceStatus);
			setServiceCategoryId(res?.data?.categoryId);
		}
	};

	const selectCoverImage = () => {
		selectCoverImageInputRef.current.click();
	};

	const removeCoverImage = () => {
		setCoverImageDisplayUrl('');
		setCoverImage('');
	};

	const uploadServiceImage = async (e) => {
		const file = e.target.files[0];
		if (!!file) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = (res) => {
				setCoverImageDisplayUrl(res.target.result);
			};
			const res = await uploadImage(file);
			setCoverImage(res);
		}
	};

	const uploadImage = async (file) => {
		const name = file.name;
		const type = file.type;
		const uniqueToken = currentProject?.unique_token;
		const { data: url } = await getServiceImageUrl(type, name, uniqueToken);
		const res = await sendImage(url, file);
		const fileUrl = url.split('?')[0];
		if (res.status === 200) {
			return fileUrl;
		}
	};

	const handleUpdateService = async (decision) => {
		const payload = {
			serviceSlug: slug,
			name: name,
			serviceCoverImage: coverImage,
			serviceDescription: description,
			metaTitle: metaTitle,
			metaDescription: metaDescription,
			serviceStatus: decision,
			serviceContent: content,
			//serviceImages: images,
			categoryId: serviceCategoryId,
		};
		console.log('payload', payload);
		await updateService(payload, serviceId);
	};

	const CreateServiceMain = () => {
		return (
			<Flex flexDir={'column'} gap="3rem">
				<Box>
					<Text
						userSelect="none"
						cursor="pointer"
						onClick={() =>
							navigate(`/service-list/${serviceCategoryId}`)
						}>
						&larr; Back
					</Text>
				</Box>
				<Flex align={'start'} justify={'space-between'}>
					<Flex flexDir={'column'} align={'start'} gap="1rem">
						<Box>
							<Heading fontSize={'6xl'} fontWeight="extrabold">
								New Service
							</Heading>
							<Text fontWeight={'light'} color={'gray'}>
								Add services here
							</Text>
						</Box>
					</Flex>
				</Flex>
				<Flex flexDir={'column'} gap={'4rem'}>
					<SectionHeadings title="Publishing Details">
						<FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdTitle fontSize={'1.5rem'} />
									<Text>Service Title</Text>
								</Flex>
								<Input
									placeholder="Service Category Name"
									w="80%"
									type="email"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Flex>
							<FormControl
								display={'flex'}
								flexDir="column"
								gap={'1rem'}
								w="100%"
								align={'center'}>
								<Flex w="100%">
									<Flex
										gap={'0.5rem'}
										align={'center'}
										w="20%">
										<MdOutlineTextsms fontSize={'1.5rem'} />
										<Text>Description</Text>
									</Flex>
									<Input
										placeholder="Description"
										w="80%"
										type="text"
										value={description}
										onChange={(e) =>
											setDescription(e.target.value)
										}
									/>
								</Flex>
							</FormControl>
							{/* <Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdOutlineTextsms fontSize={'1.5rem'} />
									<Text>Service Category</Text>
								</Flex>
								<Select
									placeholder="Select category"
									w="80%"
									value={selectedOption}
									onChange={(e) =>
										setSelectedOption(e.target.value)
									}>
									<option value="inspection-survey-docking">
										Inspection , Survey and Docking Division
									</option>
									<option value="engineering-solutions">
										Engineering Solutions Division
									</option>
									<option value="turnkey-project-management">
										Turnkey Projet Management Division
									</option>
									<option value="technology">
										Technology Division
									</option>
								</Select>
							</Flex> */}
						</FormControl>
					</SectionHeadings>
					<SectionHeadings title="SEO Details">
						<FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdTitle fontSize={'1.5rem'} />
									<Text>Slug</Text>
								</Flex>
								<Input
									placeholder="Title Slug"
									w="80%"
									type="text"
									value={slug}
									onChange={(e) => setSlug(e.target.value)}
								/>
							</Flex>
						</FormControl>

						<FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdLabelImportantOutline
										fontSize={'1.5rem'}
									/>
									<Text>Meta Title</Text>
								</Flex>
								<Input
									placeholder="Meta Title"
									w="80%"
									type="text"
									value={metaTitle}
									onChange={(e) =>
										setMetaTitle(e.target.value)
									}
								/>
							</Flex>
						</FormControl>
						<FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdOutlineTextsms fontSize={'1.5rem'} />
									<Text>Meta Description</Text>
								</Flex>
								<Input
									placeholder="Meta Description"
									w="80%"
									type="text"
									value={metaDescription}
									onChange={(e) =>
										setMetaDescription(e.target.value)
									}
								/>
							</Flex>
						</FormControl>
						{/* <FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdOutlineKeyboardAlt fontSize={'1.5rem'} />
									<Text>Keywords</Text>
								</Flex>
								<Input
									placeholder="Keywords"
									w="80%"
									type="text"
									value={metaKeywords}
									onChange={(e) =>
										setMetaKeywords(e.target.value)
									}
								/>
							</Flex>
						</FormControl> 
						<FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdOutlineImageSearch fontSize={'1.5rem'} />
									<Text>Meta Image</Text>
								</Flex>
								<input
									style={{ display: 'none' }}
									ref={selectMetaInputRef}
									accept="image/jpeg, image/png"
									onChange={uploadMetaImage}
									type="file"
								/>
								{!metaImageDisplayUrl && (
									<Button
										w="max-content"
										colorScheme={'primary'}
										onClick={selectMetaImage}>
										+ Add Image
									</Button>
								)}
							</Flex>
						</FormControl>
						{!!metaImageDisplayUrl && (
							<Box
								w="50%"
								pos="relative"
								border={'1px dashed gray'}
								p="4rem 1rem 1rem 1rem">
								<img src={metaImageDisplayUrl} alt="selected" />
								<CloseButton
									onClick={removeMetaImage}
									colorScheme={'primary'}
									pos={'absolute'}
									top="1rem"
									right={'1rem'}
								/>
							</Box>
						)}*/}
					</SectionHeadings>

					{/* IMAGE UPLOAD */}
					<SectionHeadings title="Cover Image">
						<input
							style={{ display: 'none' }}
							ref={selectCoverImageInputRef}
							accept="image/jpeg, image/png"
							onChange={uploadServiceImage}
							type="file"
						/>
						{!coverImageDisplayUrl && (
							<Button
								w="max-content"
								colorScheme={'primary'}
								onClick={selectCoverImage}>
								+ Add Image
							</Button>
						)}
						{!!coverImageDisplayUrl && (
							<Box
								w="50%"
								pos="relative"
								border={'1px dashed gray'}
								p="4rem 1rem 1rem 1rem">
								<img
									src={coverImageDisplayUrl}
									alt="selected"
								/>
								<CloseButton
									onClick={removeCoverImage}
									colorScheme={'primary'}
									pos={'absolute'}
									top="1rem"
									right={'1rem'}
								/>
							</Box>
						)}
					</SectionHeadings>
					<SectionHeadings title="Service Content">
						<RichTextEditor
							onBlogContentChange={(e) => setContent(e)}
							blogContent={content}
						/>
					</SectionHeadings>
				</Flex>
				<Flex
					w="full"
					p="0.5rem 0"
					bottom={'0'}
					pos={'sticky'}
					bg="#F5F5F5"
					mb={'2rem'}
					align={'center'}
					gap="1rem"
					justify={'end'}>
					<Button
						type="submit"
						colorScheme="primary"
						size="md"
						onClick={(e) => {
							e.preventDefault();
							handleUpdateService('Draft');
						}}>
						Save Service
					</Button>
					<Button
						type="submit"
						colorScheme="primary"
						size="md"
						onClick={(e) => {
							e.preventDefault();
							handleUpdateService('Active');
						}}>
						Publish Service
					</Button>
				</Flex>
			</Flex>
		);
	};

	const CreateServiceRightBar = () => {
		const topBlogsdata = [
			{
				title: 'Top 5 Blog Tips',
				list: 'Define Your Reader Personas;Research the Topic from the Inside Out; Optimize Your Headline for Conversion;Always Map Out the Blog Structure First,Use Keywords to Your Advantage',
			},
		];

		return (
			<>
				<Flex h="100%" justify={'space-evenly'} flexDir={'column'}>
					<Flex gap="1rem" flexDir={'column'}></Flex>
					<Flex gap="1rem" flexDir={'column'}>
						<LatestFromUs />
					</Flex>
				</Flex>
			</>
		);
	};

	return (
		<SidebarLayout>
			<MainLayout
				main={CreateServiceMain()}
				// rightBar={CreateServiceRightBar()}
			/>
		</SidebarLayout>
	);
};

export default CreateService;

import { Button, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.log(error);
		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			return (
				<Flex
					height="100vh"
					justify="center"
					align="center"
					direction="column">
					<Heading my="2%" color="primary.500">
						Uh-oh! Whatever you’re looking for doesn’t seem to
						exist.
					</Heading>
					<Heading size="sm">
						Which begs the question: How certain are you that what
						you’re looking for is actually what you need?
					</Heading>
					<Link to="/">
						<Button
							my={5}
							colorScheme="primary"
							// shadow="primaryBtn"

							type="submit">
							<b>Back to home</b>
						</Button>
					</Link>
				</Flex>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;

import { Box, Flex, Link, SimpleGrid, Text } from '@chakra-ui/react';
import { AddCard, EditPagesCard, MetaDataBar } from '../../components';
import { getPageData } from '../../content/dummy';
import { MainLayout, SidebarLayout } from '../../layouts';

const WebsitePagesMain = () => {
	return (
		<>
			<Box mb="2rem">
				<Text fontWeight={'semibold'} fontSize={'5xl'}>
					Niwi's Website
				</Text>
				<Text color={'gray.200'} fontSize={'md'}>
					Custom UI - Designed in collaboration with{' '}
					<Link
						color={'primary.500'}
						href="https://Apric.in"
						isExternal>
						Apric
					</Link>{' '}
				</Text>
			</Box>
			<Box mb="2rem">
				<MetaDataBar />
			</Box>
			<Box>
				<Text fontWeight={'semibold'} fontSize={'4xl'}>
					Pages
				</Text>
				<Text color={'gray.200'} fontSize={'sm'}>
					(Click to view & edit Projects)
				</Text>
			</Box>
			<SimpleGrid my="1rem" columns={[2, null, 4]} spacing="50px">
				<AddCard />
				<EditPagesCard getData={getPageData} />
			</SimpleGrid>
		</>
	);
};

const WebsitePagesRightBar = () => {
	return (
		<Flex>
			<h1>WebsitePagesRightBar</h1>
		</Flex>
	);
};

const WebsitePages = () => {
	return (
		<SidebarLayout>
			<MainLayout
				main={<WebsitePagesMain />}
				rightBar={<WebsitePagesRightBar />}
			/>
		</SidebarLayout>
	);
};

export default WebsitePages;

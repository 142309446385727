import {
	Button,
	Flex,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	ListItem,
	Text,
} from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

const ScheduledEmail = ({ heading, onSave, onEdit, day, template }) => {
	const [subject, setSubject] = useState('');
	const [content, setContent] = useState('');

	useEffect(() => {
		setSubject(template?.subject);
		setContent(template?.html_content);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSave = () => {
		onSave(subject, content, day);
	};

	return (
		<ListItem fontSize={'xl'} fontWeight="semibold">
			<Flex gap="1rem" flexDir={'column'}>
				<Heading fontSize={'xl'}>{heading}</Heading>
				<Flex gap={'2rem'} align={'center'}>
					<Text>Subject</Text>
					<InputGroup>
						<Input
							borderRadius={'none'}
							placeholder="write here..."
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
						/>
						<InputRightElement
							_hover={{
								cursor: 'pointer',
								color: 'primary.500',
							}}
							children={<MdEdit color="gray.500" />}
						/>
					</InputGroup>
				</Flex>
				<RichTextEditor
					onBlogContentChange={setContent}
					blogContent={content}
				/>
				<Flex justify={'end'} gap={'1rem'} mt="3rem">
					<Button
						w="8rem"
						variant={'solid'}
						bgGradient="linear(to-r, #3a5ef0 , #0f2daa )"
						colorScheme={'primary'}
						onClick={handleSave}>
						save
					</Button>
					<Button
						w="8rem"
						variant={'outline'}
						colorScheme={'primary'}>
						delete
					</Button>
				</Flex>
			</Flex>
		</ListItem>
	);
};

export default ScheduledEmail;

import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import ProfileSidebar from '../components/ProfileSidebar/ProfileSidebar';

const ProfileSidebarLayout = ({ children }) => {
	return (
		<Flex h="100vh">
			<Flex>
				<Box w="8vw" pos="fixed">
					<ProfileSidebar />
				</Box>
			</Flex>
			<Flex w="100%" flexDir={'column'} ml="8vw" p={10}>
				{children}
			</Flex>
		</Flex>
	);
};

export default ProfileSidebarLayout;

import {
	Box,
	Flex,
	Heading,
	Image,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import loaderAfterLogin from '../../assets/gifs/loading.gif';
import DashboardBlogs from '../../assets/images/dashboard_blogs.png';
import DashboardProduct from '../../assets/images/dashboard_products.png';
import AddOrganizationDialog from '../../components/Dialogs/AddOrganizationDialog/AddOrganizationDialog';
import { AuthContext } from '../../context/Auth.Context';
import { DashboardContext } from '../../context/DashboardContext';
import { MainLayout, SidebarLayout } from '../../layouts';
import OnBoardingCard from '../OnBoarding/components/OnBoardingCard';
import LatestFromUs from './components/LatestFromUs';
import YourShortcuts from './components/YourShortcuts';

const Dashboard = () => {
	const navigate = useNavigate();

	const {
		isOpen: isOrganizationOpen,
		onOpen: onOrganizationOpen,
		onClose: onOrganizationClose,
	} = useDisclosure();

	const { user, currentProject } = useContext(AuthContext);
	const { blogCount, productCount, leads, isDashboardLoading } =
		useContext(DashboardContext);

	useEffect(() => {
		checkUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const checkUser = () => {
		if (!!user) {
			const organization = user?.organization;
			if (!organization || !currentProject) {
				onOrganizationOpen();
			} else {
				onOrganizationClose();
			}
		}
	};

	const DashboardMain = () => {
		return (
			<Box background="#F5F5F5">
				<Heading fontSize={'6xl'} fontWeight="extrabold">
					Welcome, {user?.name}
				</Heading>
				<Text color="gray" fontSize={'md'}>
					Here's what happening with your website
				</Text>
				<Flex
					gap="2rem"
					align="center"
					justifyContent="space-between"
					mt="3rem">
					<OnBoardingCard
						name="Products"
						count={productCount}
						image={DashboardProduct}
					/>
					<OnBoardingCard
						name="Blogs"
						count={blogCount}
						image={DashboardBlogs}
					/>
				</Flex>
				<Flex align="center" justifyContent="space-between" mt="4rem">
					<Box>
						<Text fontWeight={'semibold'} fontSize={'2xl'}>
							Leads History
						</Text>
						<Text color="gray" fontSize={'md'}>
							Your 5 recent lead acquisition
						</Text>
					</Box>
					<Text cursor="pointer" onClick={() => navigate('/leads')}>
						View All
					</Text>
				</Flex>
				<TableContainer mt="2rem">
					<Table variant="simple">
						<Thead color="gray">
							<Tr>
								<Th>S. no</Th>
								<Th>Date</Th>
								<Th>Name</Th>
								<Th>Email</Th>
								<Th>Source</Th>
							</Tr>
						</Thead>
						<Tbody>
							{leads?.slice(0, 5).map((lead, index) => (
								<Tr key={index}>
									<Td>{index + 1}</Td>
									<Td>
										<Moment format="ll">
											{lead?.createdAt}
										</Moment>
									</Td>
									<Td>{lead?.name}</Td>
									<Td>{lead?.email}</Td>
									<Td>{lead?.source}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
				<AddOrganizationDialog
					user={user}
					isOpen={isOrganizationOpen}
					onClose={onOrganizationClose}
				/>
			</Box>
		);
	};

	const DashboardRightBar = () => {
		return (
			<Flex h="100%" justify={'space-evenly'} flexDir={'column'}>
				<Flex gap="1rem" flexDir={'column'}>
					<YourShortcuts />
				</Flex>
				<Flex gap="1rem" flexDir={'column'}>
					<LatestFromUs />
				</Flex>
			</Flex>
		);
	};

	return (
		<>
			{isDashboardLoading ? (
				<Flex
					flexDir={'column'}
					align="center"
					justify="center"
					gap={'2rem'}
					h="100vh">
					<Image
						w="40vw"
						h="40vh"
						src={loaderAfterLogin}
						alt="loader"
					/>
					<Text color={'darkgray'} fontSize={'xl'}>
						Your CMS is loading...
					</Text>
				</Flex>
			) : (
				<SidebarLayout>
					<MainLayout
						main={<DashboardMain />}
						rightBar={<DashboardRightBar />}
					/>
				</SidebarLayout>
			)}
		</>
	);
};

export default Dashboard;

import React from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const GoBack = () => {
	const navigate = useNavigate();

	return (
		<MdKeyboardBackspace
			cursor={'pointer'}
			fontSize={'2rem'}
			onClick={() => navigate(-1)}
		/>
	);
};

export default GoBack;

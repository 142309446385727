import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import noImage from '../../../assets/product/noImage.png';

const ProductList = ({ product: { images, name, category, _id } }) => {
	const navigate = useNavigate();

	const editProduct = (id) => {
		navigate(`/website-products/edit-product/${id}`);
	};

	return (
		<Flex
			data-aos="fade"
			justify={'center'}
			shadow="light"
			borderRadius={'xl'}
			minH="14rem"
			background={`url(${
				!images.length ? noImage : images[0]
			}) center/cover no-repeat`}>
			<Flex
				className="cms-glassmorphism"
				w="90%"
				borderRadius={'lg'}
				justify={'start'}
				p="0.5rem"
				position={'absolute'}
				bottom="5%">
				<Box>
					<Heading fontSize={'xl'}>{name}</Heading>
					<Text>{category}</Text>
				</Box>
			</Flex>
			<Flex
				className="cms-glassmorphism"
				w="max-content"
				borderRadius={'lg'}
				justify={'start'}
				p="0.5rem"
				position={'absolute'}
				cursor="pointer"
				opacity={'0.6'}
				_hover={{ transform: 'scale(1.1)', opacity: '1' }}
				top="5%"
				right={'5%'}>
				<Box>
					<AiOutlineEdit onClick={() => editProduct(_id)} />
				</Box>
			</Flex>
		</Flex>
	);
};

export default ProductList;

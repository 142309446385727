import axios from 'axios';
import { baseUrl } from '../utils/configs';

export const signin = async (payload) => {
	try {
		const res = await axios.post(baseUrl + `/user/login`, payload);
		return res.data;
	} catch (error) {
		return error.response;
	}
};

export const me = async () => {
	try {
		return await axios.get(baseUrl + `/user/me`, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
	} catch (error) {
		return error.response;
	}
};

export const updateUser = async (payload) => {
	try {
		return await axios.put(baseUrl + `/user`, payload, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const getGoogleRedirectUrl = async () => {
	try {
		return await axios.get(baseUrl + `/user/auth/google/url`);
	} catch (error) {
		return error.response;
	}
};

export const googleSignUp = async (param) => {
	try {
		const res = await axios.get(
			baseUrl + `/user/auth/google/callback?code=${param}`,
		);
		return res.data;
	} catch (error) {
		return error.response;
	}
};

export const createProduct = async (payload, uniqueToken) => {
	try {
		return await axios.post(baseUrl + `/${uniqueToken}/product`, payload, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
	} catch (error) {
		return error.response;
	}
};

export const getProducts = async (uniqueToken, search = '') => {
	try {
		return await axios.get(
			baseUrl + `/${uniqueToken}/product?search=${search}`,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
	} catch (error) {
		return error.response;
	}
};

export const getBlogs = async (uniqueToken, search = '') => {
	try {
		return await axios.get(
			baseUrl + `/${uniqueToken}/blog?search=${search}&limit=100`,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
	} catch (error) {
		return error.response;
	}
};

export const getBookings = async (uniqueToken) => {
	try {
		return await axios.get(baseUrl + `/${uniqueToken}/booking`, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
	} catch (error) {
		return error.response;
	}
};

export const createBlog = async (payload, uniqueToken) => {
	try {
		return await axios.post(baseUrl + `/${uniqueToken}/blog`, payload, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
	} catch (error) {
		return error.response;
	}
};

export const createServiceCategory = async (payload, uniqueToken) => {
	try {
		return await axios.post(
			baseUrl + `/${uniqueToken}/service/category`,
			payload,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
	} catch (error) {
		return error.response;
	}
};

export const sendImage = async (url, files) => {
	try {
		return await axios.put(url, files);
	} catch (error) {
		return error.response;
	}
};

export const getBlogById = async (uniqueToken, blogId) => {
	try {
		const res = await axios.get(
			baseUrl + `/${uniqueToken}/blog/${blogId}`,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
		return res.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const getImageUrl = async (type, name, uniqueToken) => {
	try {
		const res = await axios.get(
			baseUrl +
				`/${uniqueToken}/blog-signed-url?contentType=${type}&fileName=${name}`,
		);
		return res.data;
	} catch (error) {
		return error.response;
	}
};

export const getProductImageUrl = async (type, name, uniqueToken) => {
	try {
		console.log(uniqueToken);
		const res = await axios.get(
			baseUrl +
				`/${uniqueToken}/product-signed-url?contentType=${type}&fileName=${name}`,
		);
		return res.data;
	} catch (error) {
		return error.response;
	}
};

export const createProject = async (payload) => {
	try {
		return await axios.post(baseUrl + `/project`, payload, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
	} catch (error) {
		return error.response;
	}
};

export const updateProject = async (id, payload) => {
	try {
		return await axios.put(baseUrl + `/project/${id}`, payload, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
	} catch (error) {
		return error.response;
	}
};

export const getLeads = async (uniqueToken, search = '', page = 1) => {
	try {
		return await axios.get(
			baseUrl +
				`/${uniqueToken}/lead?search=${search}&page=${page}&limit=10`,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
	} catch (error) {
		return error.response;
	}
};

export const editProduct = async (uniqueToken, id, payload) => {
	try {
		return await axios.put(
			baseUrl + `/${uniqueToken}/product/${id}`,
			payload,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
	} catch (error) {
		return error.response;
	}
};

export const getProductById = async (id, uniqueToken) => {
	try {
		return await axios.get(baseUrl + `/${uniqueToken}/product/${id}`);
	} catch (error) {
		return error.response;
	}
};

export const editBlog = async (uniqueToken, id, payload) => {
	try {
		return await axios.put(
			baseUrl + `/${uniqueToken}/blog/${id}`,
			payload,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
	} catch (error) {
		return error.response;
	}
};

export const getBlogStats = async (uniqueToken) => {
	try {
		return await axios.get(baseUrl + `/${uniqueToken}/blog/count`, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
	} catch (error) {
		return error.response;
	}
};

export const getProductStats = async (uniqueToken) => {
	try {
		return await axios.get(baseUrl + `/${uniqueToken}/product/count`, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
	} catch (error) {
		return error.response;
	}
};

export const addEmailTemplate = async (uniqueToken, payload) => {
	try {
		return await axios.post(
			baseUrl + `/${uniqueToken}/email-template`,
			payload,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
	} catch (error) {
		return error.response;
	}
};

export const getAllEmailTemplate = async (uniqueToken) => {
	try {
		return await axios.get(baseUrl + `/${uniqueToken}/email-template`, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
	} catch (error) {
		return error.response;
	}
};

export const getEmailTemplateByDay = async (uniqueToken, day) => {
	try {
		return await axios.get(
			baseUrl + `/${uniqueToken}/email-template/${day}`,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
	} catch (error) {
		return error.response;
	}
};

export const getServiceCategoryImageUrl = async (type, name, uniqueToken) => {
	try {
		console.log(uniqueToken);
		const res = await axios.get(
			baseUrl +
				`/${uniqueToken}/servicecategory-signed-url?contentType=${type}&fileName=${name}`,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
		return res.data;
	} catch (error) {
		return error.response;
	}
};

export const getServiceImageUrl = async (type, name, uniqueToken) => {
	try {
		console.log(uniqueToken);
		const res = await axios.get(
			baseUrl +
				`/${uniqueToken}/service-signed-url?contentType=${type}&fileName=${name}`,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
		return res.data;
	} catch (error) {
		return error.response;
	}
};

export const getServiceCategoryById = async (uniqueToken, id) => {
	try {
		const res = await axios.get(
			baseUrl + `/${uniqueToken}/service/category/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
		return res.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const editServiceCategory = async (uniqueToken, id, payload) => {
	try {
		return await axios.post(
			baseUrl + `/${uniqueToken}/service/updatecategory/${id}`,
			payload,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
	} catch (error) {
		return error.response;
	}
};

export const getAllServicesUnderCategory = async (uniqueToken, id) => {
	try {
		return await axios.get(baseUrl + `/${uniqueToken}/services/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
	} catch (error) {
		return error.response;
	}
};

export const getServiceById = async (uniqueToken, id) => {
	try {
		const res = await axios.get(baseUrl + `/${uniqueToken}/service/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return res.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const createService = async (payload, uniqueToken) => {
	try {
		return await axios.post(
			baseUrl + `/${uniqueToken}/createservice`,
			payload,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
	} catch (error) {
		return error.response;
	}
};

export const editService = async (uniqueToken, id, payload) => {
	try {
		return await axios.post(
			baseUrl + `/${uniqueToken}/service/updateservice/${id}`,
			payload,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			},
		);
	} catch (error) {
		return error.response;
	}
};

import { Badge, Box, Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { MdOutlineEast } from 'react-icons/md';

const ServiceCards = ({ data }) => {
	const navigate = useNavigate();
	const openEditServiceCategory = (id) => {
		navigate(`/edit-servicecategory/${id}`);
	};

	const openViewServiceCategory = (id) => {
		navigate(`/service-list/${id}`);
	};
	return (
		<Flex borderRadius={'1rem'} p="1rem" gap="1rem" bg="white">
			<Box
				borderRadius={'0.5rem'}
				boxSize={'6rem'}
				background={`url(${data.coverImage}) center/cover no-repeat`}
			/>
			<Flex flexDir={'column'} justify="space-around" flex={'3'}>
				<Box>
					<Badge
						size="xs"
						variant="subtle"
						colorScheme="green"
						textTransform={'capitalize'}
						borderRadius="full">
						{data.status}
					</Badge>
					<Text fontSize={'sm'}>{data.name}</Text>
				</Box>
				<Text color="gray" fontSize={'xs'}>
					Last updated - {moment(data.createdAt).format('ll')}
				</Text>
			</Flex>
			<Flex
				flexDir={'column'}
				align="end"
				justify="space-between"
				flex={'1'}>
				<Box
					cursor={'pointer'}
					color="gray.400"
					onClick={() => openEditServiceCategory(data._id)}
					fontSize={'1.5rem'}
					_hover={{
						color: 'gray.900',
					}}>
					<AiOutlineEdit />
				</Box>
				<Box
					cursor={'pointer'}
					color="gray.400"
					onClick={() => openViewServiceCategory(data._id)}
					fontSize={'1.5rem'}
					_hover={{
						color: 'gray.900',
					}}>
					<MdOutlineEast />
				</Box>
			</Flex>
		</Flex>
	);
};

export default ServiceCards;

import React, { useContext, useEffect } from 'react';
import { Center, Spinner } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context/Auth.Context';

const GoogleAuthLogin = () => {
	const [searchParams] = useSearchParams();

	const { loginWithGoogle } = useContext(AuthContext);

	useEffect(() => {
		googleLogin().then();
	});

	const googleLogin = async () => {
		const queryCode = searchParams.get('code');
		await loginWithGoogle(queryCode);
	};

	return (
		<Center height="100vh">
			<Spinner color="blue" thickness="5px" size="xl" />
		</Center>
	);
};

export default GoogleAuthLogin;

import {
	Box,
	Button,
	CloseButton,
	Flex,
	FormControl,
	Heading,
	Input,
	Text,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
	MdLabelImportantOutline,
	MdOutlineImageSearch,
	MdOutlineKeyboardAlt,
	MdOutlineTextsms,
	MdTitle,
} from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { SectionHeadings } from '../../components';
import { AuthContext } from '../../context/Auth.Context';
import { MainLayout, SidebarLayout } from '../../layouts';
import {
	getProductById,
	getProductImageUrl,
	sendImage,
} from '../../services/requests';
import { DashboardContext } from '../../context/DashboardContext';

const EditProduct = () => {
	const [productImage, setProductImage] = useState([]);
	const [displayUrl, setDisplayUrl] = useState([]);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [category, setCategory] = useState('');
	const [variant, setVariant] = useState('');
	const [price, setPrice] = useState('');

	const { user } = useContext(AuthContext);
	const { updateProduct } = useContext(DashboardContext);

	const selectInputRef = useRef();

	const navigate = useNavigate();

	const params = useParams();

	useEffect(() => {
		getProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getProduct = async () => {
		const id = params.id;
		const uniqueToken = user?.default_project?.unique_token;
		const res = await getProductById(id, uniqueToken);
		setProductImage(res?.data?.data?.images);
		setDisplayUrl(res?.data?.data?.images);
		setName(res?.data?.data?.name);
		setDescription(res?.data?.data?.description);
		setCategory(res?.data?.data?.category);
		setVariant(res?.data?.data?.variant);
		setPrice(res?.data?.data?.price);
	};

	const selectFile = () => {
		selectInputRef.current.click();
	};

	const uploadImage = async (file) => {
		const name = file.name;
		const type = file.type;
		const projectId = user?.default_project?._id;
		const { data: url } = await getProductImageUrl(type, name, projectId);
		const res = await sendImage(url, file);
		const fileUrl = url.split('?')[0];
		if (res.status === 200) {
			setProductImage((state) => [...state, fileUrl]);
		}
	};

	const fileSelected = async (e) => {
		const file = e.target.files[0];
		if (!!file) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = (res) => {
				setDisplayUrl((state) => [...state, res.target.result]);
			};
			await uploadImage(file);
		}
	};

	const removeImage = async (index) => {
		setDisplayUrl([
			...displayUrl.slice(0, index),
			...displayUrl.slice(index + 1, displayUrl.length),
		]);
		setProductImage([
			...displayUrl.slice(0, index),
			...displayUrl.slice(index + 1, displayUrl.length),
		]);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const payload = {
			name,
			description,
			images: productImage,
			category,
			variant,
			price,
		};
		const id = params.id;
		const res = await updateProduct(payload, id);
		if (res === 'success') {
			navigate('/website-products');
		}
	};

	const AddProductFrom = () => (
		<Flex flexDir={'column'} gap="3rem">
			<Flex align={'start'} justify={'space-between'}>
				<Flex flexDir={'column'} align={'start'} gap="1rem">
					<Box>
						<Heading fontSize={'6xl'} fontWeight="extrabold">
							Edit Product
						</Heading>
						<Text fontWeight={'light'} color={'gray'}>
							Expand Your Catalogue
						</Text>
					</Box>
				</Flex>
			</Flex>
			<Flex flexDir={'column'} gap={'4rem'}>
				<SectionHeadings title="Product Details">
					<FormControl
						display={'flex'}
						flexDir="column"
						gap={'1rem'}
						w="100%"
						align={'center'}>
						<Flex w="100%">
							<Flex gap={'0.5rem'} align={'center'} w="20%">
								<MdTitle fontSize={'1.5rem'} />
								<Text>Product Name</Text>
							</Flex>
							<Input
								w="80%"
								isRequired
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="Product Name"
								type="text"
							/>
						</Flex>
					</FormControl>
					<FormControl
						display={'flex'}
						flexDir="column"
						gap={'1rem'}
						w="100%"
						align={'center'}>
						<Flex w="100%">
							<Flex gap={'0.5rem'} align={'center'} w="20%">
								<MdLabelImportantOutline fontSize={'1.5rem'} />
								<Text>Description</Text>
							</Flex>
							<Input
								w="80%"
								isRequired
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								placeholder="Description"
								type="text"
							/>
						</Flex>
					</FormControl>
					<FormControl
						display={'flex'}
						flexDir="column"
						gap={'1rem'}
						w="100%"
						align={'center'}>
						<Flex w="100%">
							<Flex gap={'0.5rem'} align={'center'} w="20%">
								<MdOutlineTextsms fontSize={'1.5rem'} />
								<Text>Category</Text>
							</Flex>
							<Input
								w="80%"
								isRequired
								value={category}
								onChange={(e) => setCategory(e.target.value)}
								placeholder="Category"
								type="text"
							/>
						</Flex>
					</FormControl>
					<FormControl
						display={'flex'}
						flexDir="column"
						gap={'1rem'}
						w="100%"
						align={'center'}>
						<Flex w="100%">
							<Flex gap={'0.5rem'} align={'center'} w="20%">
								<MdOutlineKeyboardAlt fontSize={'1.5rem'} />
								<Text>Variant</Text>
							</Flex>
							<Input
								w="80%"
								isRequired
								value={variant}
								onChange={(e) => setVariant(e.target.value)}
								placeholder="Variant"
								type="text"
							/>
						</Flex>
					</FormControl>
					<FormControl
						display={'flex'}
						flexDir="column"
						gap={'1rem'}
						w="100%"
						align={'center'}>
						<Flex w="100%">
							<Flex gap={'0.5rem'} align={'center'} w="20%">
								<MdOutlineImageSearch fontSize={'1.5rem'} />
								<Text>Price</Text>
							</Flex>

							<Input
								w="80%"
								isRequired
								value={price}
								onChange={(e) => setPrice(e.target.value)}
								placeholder="Price"
								type="text"
							/>
						</Flex>
					</FormControl>
				</SectionHeadings>
				<SectionHeadings title="Product Image">
					<input
						style={{ display: 'none' }}
						ref={selectInputRef}
						onChange={fileSelected}
						type="file"
					/>
					<Button
						w="max-content"
						colorScheme={'primary'}
						onClick={selectFile}>
						+ Add Image
					</Button>

					{displayUrl?.length > 0 &&
						displayUrl.map((src, index) => (
							<Flex
								key={index}
								w="50%"
								pos="relative"
								border={'1px dashed gray'}
								p="4rem 1rem 1rem 1rem">
								<img src={src} alt="selected" />
								<CloseButton
									onClick={() => removeImage(index)}
									colorScheme={'primary'}
									pos={'absolute'}
									top="1rem"
									right={'1rem'}
								/>
							</Flex>
						))}
				</SectionHeadings>
			</Flex>
			<Button
				my={5}
				width="35%"
				colorScheme="primary"
				shadow="primaryBtn"
				onClick={handleSubmit}>
				Edit Product
			</Button>
		</Flex>
	);

	return (
		<SidebarLayout>
			<MainLayout main={AddProductFrom()} />
		</SidebarLayout>
	);
};

export default EditProduct;

import {
	Box,
	Button,
	Flex,
	Grid,
	Heading,
	Image,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Text,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { MdOutlineCancel, MdSearch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import noProduct from '../../assets/product/noData.png';
import { DashboardContext } from '../../context/DashboardContext';
import { MainLayout } from '../../layouts';
import SidebarLayout from '../../layouts/SidebarLayout';
import LatestFromUs from '../Dashboard/components/LatestFromUs';
import ProductList from './components/ProductList';
import Stats from './components/Stats';
import product from '../../assets/rightBar/one.png';

const WebsiteProducts = () => {
	const [searchQuery, setSearchQuery] = useState('');

	const navigate = useNavigate();

	const { products, productCount, getAllProducts } =
		useContext(DashboardContext);

	const handleSearch = async (e) => {
		setSearchQuery(e.target.value);
		if (!!searchQuery) {
			await getAllProducts(searchQuery);
		}
	};

	const clearSearch = async () => {
		setSearchQuery('');
		await getAllProducts();
	};

	const WebsiteProductMain = () => {
		return (
			<Flex flexDir={'column'} gap="3rem">
				<Box>
					<Flex alignItems="center" justifyContent="space-between">
						<Heading fontSize={'6xl'} fontWeight="extrabold">
							Products
						</Heading>

						<Button
							bgGradient="linear(to-r, #3a5ef0 , #0f2daa )"
							colorScheme="primary"
							shadow="primaryBtn"
							onClick={() =>
								navigate('/website-products/add-product')
							}>
							<b>Add Product&nbsp;&nbsp;+</b>
						</Button>
					</Flex>
					<Text fontWeight={'light'} color={'gray'}>
						Add, Edit & Delete products from your Apric Catalogue
					</Text>
				</Box>
				<Flex align={'center'}>
					<Text fontSize={'xl'} fontWeight="extrabold" mr="auto">
						All Products
					</Text>
					<InputGroup w="max-content">
						<InputLeftElement pointerEvents="none">
							<MdSearch fontSize={'1.5rem'} color="gray" />
						</InputLeftElement>
						<Input
							borderRadius={'2rem'}
							fontSize={'lg'}
							type="text"
							placeholder="search"
							value={searchQuery}
							onChange={handleSearch}
						/>
						<InputRightElement>
							{searchQuery && (
								<MdOutlineCancel
									fontSize="1.5rem"
									cursor="pointer"
									onClick={clearSearch}
								/>
							)}
						</InputRightElement>
					</InputGroup>

					{/* <Box fontSize={'1.5rem'} color="gray" mx="1rem">
					<MdOutlineFilterList />
				</Box> */}
				</Flex>
				{products.length < 1 && (
					<Flex>
						<Image w="80%" alt="no product image" src={noProduct} />
					</Flex>
				)}
				<Grid
					templateColumns="repeat(auto-fit, 14rem)"
					justifyContent={'space-between'}
					gap="3rem">
					{products.map((product, i) => (
						<Flex key={i} position={'relative'} flexDir={'column'}>
							<ProductList product={product} />
						</Flex>
					))}
				</Grid>
			</Flex>
		);
	};

	const WebsiteProductRightBar = () => {
		return (
			<Flex h="100%" justify={'space-evenly'} flexDir={'column'}>
				<Flex gap="1rem" flexDir={'column'}>
					<Heading fontSize={'2xl'} fontWeight="semibold">
						Stats
					</Heading>
					<Flex flexDir={'column'} gap={'1rem'} w="100%">
						<Stats
							image={product}
							count={productCount}
							statsHeading="Product"
						/>
					</Flex>
				</Flex>
				<Flex gap="1rem" flexDir={'column'}>
					<LatestFromUs />
				</Flex>
			</Flex>
		);
	};

	return (
		<SidebarLayout>
			<MainLayout
				main={WebsiteProductMain()}
				rightBar={WebsiteProductRightBar()}
			/>
		</SidebarLayout>
	);
};

export default WebsiteProducts;

import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	VStack,
} from '@chakra-ui/react';
import { AuthContext } from '../../../context/Auth.Context';

const AddOrganizationDialog = ({ isOpen, onClose, user }) => {
	const [organization, setOrganization] = useState('');
	const [project, setProject] = useState('');

	const { updateOrganizationAndProject, currentProject, userLoading } =
		useContext(AuthContext);

	const handelSubmit = async (e) => {
		e.preventDefault();
		if (!organization || !project) {
			return;
		}
		await updateOrganizationAndProject(organization, project);
	};

	useEffect(() => {
		setOrganization(user?.organization);
		setProject(currentProject?.name);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	return (
		<Modal
			closeOnOverlayClick={false}
			closeOnEsc={false}
			isOpen={isOpen}
			onClose={onClose}
			motionPreset="scale"
			size="xl"
			isCentered>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
			<ModalContent p={6}>
				<ModalHeader textAlign="center">Add Organization</ModalHeader>
				<ModalBody textAlign="center">
					<form onSubmit={handelSubmit}>
						<VStack spacing={6}>
							<Input
								isRequired
								name="organization"
								value={organization || ''}
								onChange={(e) =>
									setOrganization(e.target.value)
								}
								placeholder="Add Organization"
								type="text"
							/>
							<Input
								isRequired
								name="project"
								value={project || ''}
								onChange={(e) => setProject(e.target.value)}
								placeholder="Add Project"
								type="text"
							/>
							<Button
								isLoading={userLoading}
								width="100%"
								colorScheme="primary"
								shadow="primaryBtn"
								type="submit">
								<b>Save</b>
							</Button>{' '}
						</VStack>
					</form>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default AddOrganizationDialog;

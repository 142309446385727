import {
	Button,
	Flex,
	Heading,
	Image,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	SimpleGrid,
	Text,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { MdOutlineCancel, MdSearch } from 'react-icons/md';
import { Link } from 'react-router-dom';
import noBlog from '../../assets/blogpage/noData.png';
import { DashboardContext } from '../../context/DashboardContext';
import { MainLayout, SidebarLayout } from '../../layouts';
import BlogCards from '../Blog/components/BlogCards';
import LatestFromUs from '../Dashboard/components/LatestFromUs';
import Stats from '../WebsiteProducts/components/Stats';
import blogs_logo from '../../assets/rightBar/blogs.png';

const BlogsList = () => {
	const [searchQuery, setSearchQuery] = useState('');

	const { blogs, getAllBlogs, blogCount } = useContext(DashboardContext);

	const handleSearch = async (e) => {
		setSearchQuery(e.target.value);
		if (!!searchQuery) {
			await getAllBlogs(searchQuery);
		}
	};

	const clearSearch = async () => {
		setSearchQuery('');
		await getAllBlogs();
	};

	const BlogsListMain = () => {
		return (
			<Flex flexDir={'column'} gap="3rem">
				<Flex align={'start'} justify={'space-between'}>
					<Flex flexDir={'column'}>
						<Heading fontSize={'6xl'} fontWeight="extrabold">
							Blogs
						</Heading>
						<Text fontWeight={'light'} color={'gray'}>
							Let your creativity flow here
						</Text>
					</Flex>
					<Link to={`/create-blog`}>
						<Button
							bgGradient="linear(to-r, #3a5ef0 , #0f2daa )"
							colorScheme="primary"
							size="md">
							Create New Blog&nbsp;&nbsp;+
						</Button>
					</Link>
				</Flex>
				<Flex align={'center'}>
					<Text fontSize={'xl'} fontWeight="extrabold" mr="auto">
						All Blogs
					</Text>
					<InputGroup w="max-content">
						<InputLeftElement pointerEvents="none">
							<MdSearch fontSize={'1.5rem'} color="gray" />
						</InputLeftElement>
						<Input
							borderRadius={'2rem'}
							fontSize={'lg'}
							type="text"
							placeholder="search"
							value={searchQuery}
							onChange={handleSearch}
						/>
						<InputRightElement>
							{searchQuery && (
								<MdOutlineCancel
									fontSize="1.5rem"
									cursor="pointer"
									onClick={clearSearch}
								/>
							)}
						</InputRightElement>
					</InputGroup>
				</Flex>

				{blogs.length < 1 && (
					<Flex>
						<Image w="80%" alt="no product image" src={noBlog} />
					</Flex>
				)}

				<SimpleGrid columns={2} spacing={10}>
					{/* {blogs?.length > 0 ? ( */}
					{blogs.length > 0 &&
						blogs.map((blog, index) => (
							<div key={index}>
								<BlogCards data={blog} />
							</div>
						))}
					{/* ) : (
					<Flex>
						<Image w="100%" alt="no blogs image" src={noBlog} />
					</Flex>
					)} */}
				</SimpleGrid>
			</Flex>
		);
	};

	const BlogsListMainRightBar = () => {
		return (
			<Flex h="100%" justify={'space-evenly'} flexDir={'column'}>
				<Flex gap="1rem" flexDir={'column'}>
					<Heading fontSize={'2xl'} fontWeight="semibold">
						Stats
					</Heading>
					<Flex flexDir={'column'} gap={'1rem'} w="100%">
						<Stats
							image={blogs_logo}
							count={blogCount}
							statsHeading="Total blogs"
						/>
					</Flex>
				</Flex>
				<Flex gap="1rem" flexDir={'column'}>
					<LatestFromUs />
				</Flex>
			</Flex>
		);
	};

	return (
		<SidebarLayout>
			<MainLayout
				main={BlogsListMain()}
				rightBar={BlogsListMainRightBar()}
			/>
		</SidebarLayout>
	);
};

export default BlogsList;

import { Heading, ListItem, UnorderedList } from '@chakra-ui/react';
import React from 'react';

const TopInfoCard = ({ data }) => {
	const { title, list } = data[0];
	return (
		<>
			<Heading
				textTransform={'capitalize'}
				fontSize={'2xl'}
				fontWeight="semibold">
				{title}
			</Heading>
			<UnorderedList color="gray" fontSize={'sm'}>
				{list.split(';').map((data, i) => (
					<ListItem>{data}</ListItem>
				))}
			</UnorderedList>
		</>
	);
};

export default TopInfoCard;

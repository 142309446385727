import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const Stats = ({ count, statsHeading, image }) => {
	return (
		<Flex
			borderRadius={'lg'}
			bg="white"
			p="0.6rem"
			justify={'space-between'}
			align="center"
			gap="0.5rem"
			shadow={'md'}>
			<Box
				borderRadius={'sm'}
				background={`url(${image}) center/cover no-repeat`}
				boxSize={'2rem'}
			/>
			<Flex align={'center'} justify="space-between" flex={4}>
				<Text fontSize={'sm'}> {statsHeading} </Text>
				<Text px="0.5rem" fontWeight={'extrabold'}>
					{count}
				</Text>
			</Flex>
		</Flex>
	);
};

export default Stats;

import { useToast } from '@chakra-ui/react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	addEmailTemplate,
	createBlog,
	createServiceCategory,
	createProduct,
	editBlog,
	editProduct,
	editServiceCategory,
	getBlogs,
	getBlogStats,
	getBookings,
	getEmailTemplateByDay,
	getLeads,
	getProducts,
	getProductStats,
	createService,
	editService,
} from '../services/requests';
import { AuthContext } from './Auth.Context';
import axios from 'axios';
import { baseUrl } from '../utils/configs';

export const DashboardContext = createContext({
	blogs: [],
	products: [],
	productCount: 0,
	bookings: [],
	leads: [],
	leadsPageInfo: null,
	emailTemplate: null,
	isDashboardLoading: false,
	clearDashboardState: undefined,
	populateDashboardState: undefined,
	upsertEmailTemplate: undefined,
});

export const DashboardProvider = ({ children }) => {
	const [isDashboardLoading, setIsDashboardLoading] = useState(false);
	const [blogs, setBlogs] = useState([]);
	const [serviceCategories, setServiceCategories] = useState([]);
	const [services, setServices] = useState([]);
	const [products, setProducts] = useState([]);
	const [bookings, setBookings] = useState([]);
	const [productCount, setProductCount] = useState(0);
	const [blogCount, setBlogCount] = useState(0);
	const [serviceCategoryCount, setServiceCategoryCount] = useState(0);
	const [servicesCount, setServicesCount] = useState(0);
	const [leads, setLeads] = useState([]);
	const [leadsPageInfo, setLeadsPageInfo] = useState(null);
	const [emailTemplate0, setEmailTemplate0] = useState(null);
	const [emailTemplate1, setEmailTemplate1] = useState(null);
	const [emailTemplate2, setEmailTemplate2] = useState(null);
	const [emailTemplate3, setEmailTemplate3] = useState(null);
	const [emailTemplate4, setEmailTemplate4] = useState(null);

	const toast = useToast();
	const navigate = useNavigate();

	const { currentProject, logout } = useContext(AuthContext);

	const checkResponse = async (res) => {
		if (res?.status === 401) {
			toast({
				status: 'error',
				title: 'Unauthorized. Try logging in again!',
				position: 'bottom',
				variant: 'left-accent',
			});
			await clearDashboardState();
			await logout(true);
		}
	};

	const getAllProducts = async (search) => {
		const uniqueToken = currentProject?.unique_token;
		const res = await getProducts(uniqueToken, search);
		await checkResponse(res);
		setProducts(res.data?.data);
	};

	const getAllBlogs = async (search) => {
		const uniqueToken = currentProject?.unique_token;
		const res = await getBlogs(uniqueToken, search);
		await checkResponse(res);
		setBlogs(res.data?.data);
	};

	const getProductCount = async () => {
		const uniqueToken = currentProject?.unique_token;
		const res = await getProductStats(uniqueToken);
		await checkResponse(res);
		setProductCount(res.data?.data);
	};

	const getBlogCount = async () => {
		const uniqueToken = currentProject?.unique_token;
		const res = await getBlogStats(uniqueToken);
		await checkResponse(res);
		setBlogCount(res.data?.data);
	};

	const getProjectLeads = async (search, page) => {
		const uniqueToken = currentProject?.unique_token;
		if (!!uniqueToken) {
			const res = await getLeads(uniqueToken, search, page);
			if (res.status === 200) {
				const { data, ...pageInfo } = res.data;
				await checkResponse(res);
				setLeads(data);
				setLeadsPageInfo(pageInfo);
			} else {
				toast({
					status: 'error',
					title: 'Something went wrong. Try again after sometime!',
					position: 'bottom',
					variant: 'left-accent',
				});
			}
		}
	};
	const getProjectBookings = async () => {
		try {
			const uniqueToken = currentProject?.unique_token;
			const res = await getBookings(uniqueToken);
			await checkResponse(res);
			setBookings(res.data?.data);
		} catch (e) {
			console.log(e);
		}
	};

	const clearDashboardState = async () => {
		await setBlogs([]);
		await setProducts([]);
		await setBookings([]);
		await setLeads([]);
		await setProductCount(0);
		await setBlogCount(0);
		await setEmailTemplate0(null);
		await setEmailTemplate1(null);
		await setEmailTemplate2(null);
		await setEmailTemplate3(null);
		await setEmailTemplate4(null);
	};

	const populateDashboardState = async () => {
		if (!!currentProject) {
			setIsDashboardLoading(true);
			const p1 = getAllProducts();
			const p2 = getAllBlogs();
			const p3 = getProductCount();
			const p4 = getProjectLeads();
			const p5 = getProjectBookings();
			const p6 = getBlogCount();
			const p7 = getEmailTemplate0();
			const p8 = getEmailTemplate1();
			const p9 = getEmailTemplate2();
			const p10 = getEmailTemplate3();
			const p11 = getEmailTemplate4();
			Promise.allSettled([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11])
				.then()
				.finally(() => {
					setTimeout(() => {
						setIsDashboardLoading(false);
					}, 1000);
				});
		}
	};

	const addProduct = async (payload) => {
		const uniqueToken = currentProject?.unique_token;
		const res = await createProduct(payload, uniqueToken);
		if (res.status === 200) {
			toast({
				title: `Product Added`,
				status: 'success',
				isClosable: true,
			});
			await setProducts((state) => [...state, res.data?.data]);
			await setProductCount((currentCount) => currentCount + 1);
			navigate('/website-products');
		} else {
			toast({
				title: res.data.message,
				status: 'error',
				variant: 'left-accent',
			});
		}
	};

	const updateProduct = async (payload, id) => {
		const uniqueToken = currentProject?.unique_token;
		const res = await editProduct(uniqueToken, id, payload);
		if (res.status === 200) {
			toast({
				title: 'Product Updated',
				status: 'success',
				isClosable: true,
			});
			await setProducts((prevState) =>
				prevState.map((product) =>
					product._id === id ? payload : product,
				),
			);
			navigate('/website-products');
		} else {
			toast({
				title: res.data.message,
				status: 'error',
				variant: 'left-accent',
			});
		}
	};

	const addBlog = async (payload) => {
		const uniqueToken = currentProject?.unique_token;
		const res = await createBlog(payload, uniqueToken);
		if (res.status === 200) {
			toast({
				title: `Blog Added`,
				status: 'success',
				isClosable: true,
			});
			await setBlogs((state) => [...state, res.data?.data]);
			await setBlogCount((currentCount) => currentCount + 1);
			await navigate('/blogs-list');
		} else {
			toast({
				title: res.data.message,
				status: 'error',
				variant: 'left-accent',
			});
		}
	};

	const addServiceCategory = async (payload) => {
		const uniqueToken = currentProject?.unique_token;
		const res = await createServiceCategory(payload, uniqueToken);
		if (res.status === 200) {
			toast({
				title: `Service Catgory Added`,
				status: 'success',
				isClosable: true,
			});
			await setServiceCategories((state) => [...state, res.data?.data]);
			await setServiceCategoryCount((currentCount) => currentCount + 1);
			await navigate('/categoryservice-list');
		} else {
			toast({
				title: res.data.message,
				status: 'error',
				variant: 'left-accent',
			});
		}
	};

	const getServiceCategories = async () => {
		try {
			const uniqueToken = currentProject?.unique_token;
			const res = await axios.get(
				baseUrl + '/' + uniqueToken + '/servicecategories',
				{
					headers: {
						'Content-Type': 'application/json',
						authorization: `Bearer ${localStorage.getItem(
							'token',
						)}`,
					},
				},
			);
			setServiceCategories(res.data.data);
			setServiceCategoryCount(res.data.count);
		} catch (error) {
			console.log(error);
		}
	};

	const updateBlog = async (payload, id) => {
		const uniqueToken = currentProject?.unique_token;
		const res = await editBlog(uniqueToken, id, payload);
		if (res.status === 200) {
			toast({
				title: 'Blog Updated',
				status: 'success',
				isClosable: true,
			});
			await setBlogs((prevState) =>
				prevState.map((blog) =>
					blog._id === id ? res.data.data : blog,
				),
			);
			await navigate('/blogs-list');
		} else {
			toast({
				title: res.data.message,
				status: 'error',
				variant: 'left-accent',
			});
		}
	};

	const populateEmailTemplate = async (payload, day) => {
		switch (day) {
			case 0:
				setEmailTemplate0(payload);
				break;
			case 1:
				setEmailTemplate1(payload);
				break;
			case 2:
				setEmailTemplate2(payload);
				break;
			case 3:
				setEmailTemplate3(payload);
				break;
			case 4:
				setEmailTemplate4(payload);
				break;
			default:
				setEmailTemplate0(null);
				setEmailTemplate1(null);
				setEmailTemplate2(null);
				setEmailTemplate3(null);
				setEmailTemplate4(null);
		}
	};

	const upsertEmailTemplate = async (payload, day) => {
		const uniqueToken = currentProject?.unique_token;
		const res = await addEmailTemplate(uniqueToken, payload);
		if (res.status === 200) {
			toast({
				title: 'Email Template Updated',
				status: 'success',
				isClosable: true,
			});
			await populateEmailTemplate(payload, day);
		} else {
			toast({
				title: res.data.message,
				status: 'error',
				variant: 'left-accent',
			});
		}
	};

	const getEmailTemplate0 = async () => {
		const uniqueToken = currentProject?.unique_token;
		const res = await getEmailTemplateByDay(uniqueToken, 0);
		await checkResponse(res);
		setEmailTemplate0(res.data?.data);
	};

	const getEmailTemplate1 = async () => {
		const uniqueToken = currentProject?.unique_token;
		const res = await getEmailTemplateByDay(uniqueToken, 1);
		await checkResponse(res);
		setEmailTemplate1(res.data?.data);
	};

	const getEmailTemplate2 = async () => {
		const uniqueToken = currentProject?.unique_token;
		const res = await getEmailTemplateByDay(uniqueToken, 2);
		await checkResponse(res);
		setEmailTemplate2(res.data?.data);
	};

	const getEmailTemplate3 = async () => {
		const uniqueToken = currentProject?.unique_token;
		const res = await getEmailTemplateByDay(uniqueToken, 3);
		await checkResponse(res);
		setEmailTemplate3(res.data?.data);
	};

	const getEmailTemplate4 = async () => {
		const uniqueToken = currentProject?.unique_token;
		const res = await getEmailTemplateByDay(uniqueToken, 4);
		await checkResponse(res);
		setEmailTemplate4(res.data?.data);
	};

	useEffect(() => {
		populateDashboardState().then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentProject]);

	const updateServiceCategory = async (payload, id) => {
		const uniqueToken = currentProject?.unique_token;
		const res = await editServiceCategory(uniqueToken, id, payload);
		if (res.status === 200) {
			toast({
				title: 'Service Category Updated',
				status: 'success',
				isClosable: true,
			});
			await setServiceCategories((prevState) =>
				prevState.map((data) =>
					data._id === id ? res.data.data : data,
				),
			);
			await navigate('/categoryservice-list');
		} else {
			toast({
				title: res.data.message,
				status: 'error',
				variant: 'left-accent',
			});
		}
	};

	const getServicsUnderCategory = async (id) => {
		try {
			const uniqueToken = currentProject?.unique_token;
			const res = await axios.get(
				baseUrl + '/' + uniqueToken + `/services/${id}`,
				{
					headers: {
						'Content-Type': 'application/json',
						authorization: `Bearer ${localStorage.getItem(
							'token',
						)}`,
					},
				},
			);
			setServices(res.data.data);
			setServicesCount(res.data.count);
		} catch (error) {
			console.log(error);
		}
	};

	const addService = async (payload) => {
		const uniqueToken = currentProject?.unique_token;
		const res = await createService(payload, uniqueToken);
		if (res.status === 200) {
			toast({
				title: `Service Added`,
				status: 'success',
				isClosable: true,
			});
			await setServices((state) => [...state, res.data?.data]);
			await setServicesCount((currentCount) => currentCount + 1);
			await navigate(`/service-list/${payload.categoryId}`);
		} else {
			toast({
				title: res.data.message,
				status: 'error',
				variant: 'left-accent',
			});
		}
	};

	const updateService = async (payload, id) => {
		const uniqueToken = currentProject?.unique_token;
		const res = await editService(uniqueToken, id, payload);
		if (res.status === 200) {
			toast({
				title: 'Service Updated',
				status: 'success',
				isClosable: true,
			});
			await setServices((prevState) =>
				prevState.map((data) =>
					data._id === id ? res.data.data : data,
				),
			);
			await navigate(`/service-list/${payload.categoryId}`);
		} else {
			toast({
				title: res.data.message,
				status: 'error',
				variant: 'left-accent',
			});
		}
	};

	return (
		<DashboardContext.Provider
			value={{
				blogs,
				products,
				bookings,
				productCount,
				leads,
				serviceCategories,
				services,
				leadsPageInfo,
				blogCount,
				isDashboardLoading,
				emailTemplate0,
				emailTemplate1,
				emailTemplate2,
				emailTemplate3,
				emailTemplate4,
				populateDashboardState,
				clearDashboardState,
				getAllBlogs,
				getAllProducts,
				addProduct,
				updateBlog,
				addBlog,
				addServiceCategory,
				updateServiceCategory,
				getServiceCategories,
				serviceCategoryCount,
				servicesCount,
				updateProduct,
				getProjectLeads,
				upsertEmailTemplate,
				getServicsUnderCategory,
				addService,
				updateService,
			}}>
			{children}
		</DashboardContext.Provider>
	);
};

export default DashboardProvider;

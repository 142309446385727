import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { BsPerson } from 'react-icons/bs';
import { BiArrowBack } from 'react-icons/bi';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/navbar/apric-logo.svg';
import launch from '../../assets/sidebar/launch.svg';

const profileSidebarOptions = [
	{
		title: 'Back',
		icon: <BiArrowBack />,
		route: '/dashboard',
		disable: false,
	},
	{
		title: 'Profile',
		icon: <BsPerson />,
		route: '/profile',
		disable: false,
	},
];

const ProfileSidebar = () => {
	const navigate = useNavigate();

	const navigateTo = async (route, disabled) => {
		if (!disabled) {
			await navigate(route);
		}
	};
	return (
		<Flex
			justify={'space-between'}
			flexDir={'column'}
			h="100vh"
			bg="secondary.500">
			<Flex gap={'0.5rem'} flexDir={'column'} p="1rem">
				<Flex
					align={'center'}
					justify="center"
					gap={'0.5rem'}
					borderRadius="4px"
					p="0.5rem 1rem">
					<Box py="2rem" w="2.5rem">
						<Image alt="apric logo" src={logo} />
					</Box>
				</Flex>
				<Flex gap={'0.5rem'} flexDir={'column'}>
					{profileSidebarOptions?.map(
						({ title, icon, route, disable }, i) => (
							<NavLink
								key={i}
								className={({ isActive }) =>
									isActive
										? 'activeSidebar'
										: 'inActiveSidebar'
								}
								onClick={() => navigateTo(route, disable)}
								to={route}>
								<Button
									isDisabled={disable}
									display={'flex'}
									flexDir="column"
									w="100%"
									h="auto"
									p="0.5rem"
									gap="0.5rem"
									bg={'transparent'}>
									<Box fontSize={'1.5rem'}>{icon}</Box>
									<Text fontSize={'xs'}>{title}</Text>
								</Button>
							</NavLink>
						),
					)}
				</Flex>
			</Flex>
			<Flex
				gap={'0.5rem'}
				p="1rem"
				mb="2rem"
				align={'center'}
				justify="center">
				<Image alt="launch" src={launch} />
			</Flex>
		</Flex>
	);
};

export default ProfileSidebar;

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Icon,
	Text,
} from '@chakra-ui/react';
import { MdOutlineDragIndicator } from 'react-icons/md';

const SectionAccordion = ({ data: { title } }) => {
	return (
		<Accordion allowMultiple>
			<AccordionItem
				borderRadius={'lg'}
				shadow={'base'}
				border={'0.5px solid lightgray'}>
				<h2>
					<AccordionButton p="0.5rem 1rem">
						<Icon
							mr="0.5rem"
							fontSize={'1.5rem'}
							color={'gray.300'}
							as={MdOutlineDragIndicator}
						/>
						<Box
							display={'flex'}
							alignItems={'center'}
							fontWeight={'bold'}
							flex="1"
							fontsize={'lg'}
							textAlign="left">
							{title}
							<Text
								ml="1rem"
								color={'gray.200'}
								fontSize={'md'}
								fontWeight={'normal'}
								as={'span'}>
								(Click to view & edit)
							</Text>
						</Box>

						<AccordionIcon />
					</AccordionButton>
				</h2>
				<AccordionPanel pb={4}>Rich text editor</AccordionPanel>
			</AccordionItem>
		</Accordion>
	);
};

export default SectionAccordion;

import { Box, Heading, Icon, Text } from '@chakra-ui/react';
import { BiPlusCircle } from 'react-icons/bi';
import AddCardLayout from '../../layouts/AddCardLayout';

const AddCard = () => {
	return (
		<AddCardLayout>
			<Box
				p="3rem 0"
				display={'flex'}
				color="primary.500"
				justifyContent={'center'}
				alignItems={'center'}
				fontSize={'8xl'}>
				<Icon as={BiPlusCircle} />
			</Box>
			<Heading
				borderTop="1px solid lightgray"
				display={'flex'}
				justifyContent={'center'}
				alignItems={'center'}
				size="md"
				fontWeight={'normal'}
				p="0.8rem 0">
				<Text>Add New</Text>
			</Heading>
		</AddCardLayout>
	);
};

export default AddCard;

import {
	Avatar,
	Box,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/Auth.Context';

const MainLayout = ({ main, rightBar }) => {
	const { user, currentProject, logout } = useContext(AuthContext);
	const navigate = useNavigate();

	const goToProfile = async () => {
		await navigate('/profile');
	};

	return (
		<Flex>
			<Flex bgGradient="linear(to-l, secondary.50, secondary.500)">
				<Flex
					bg="#F5F5F5"
					borderRadius={'2rem'}
					w="70vw"
					minH="100vh"
					p="5rem"
					shadow={'6xl'}
					flexDir={'column'}>
					{main}
				</Flex>
			</Flex>
			<Flex>
				<Box w="22vw" p="3rem" h="100vh" bg="secondary.50" pos="fixed">
					<Flex
						align={'center'}
						justify={'space-between'}
						w="80%"
						m="auto">
						<Flex pe="1rem" align={'center'}>
							{/* <Box>
								<Avatar size="sm" ml={-1} mr={2} />
							</Box> */}
							<Box>
								<Text
									alignSelf={'start'}
									fontWeight="semibold"
									textTransform={'capitalize'}
									fontSize={'xl'}>
									{user?.organization}
								</Text>
								<Text
									textTransform={'capitalize'}
									alignSelf={'start'}
									fontSize={'small'}>
									{currentProject?.name}
								</Text>
							</Box>
						</Flex>
						<Menu>
							<Flex borderLeft={'1px solid gray'} ps="1rem">
								<Flex justify={'center'} align="center">
									<Avatar
										// src="https://bit.ly/sage-adebayo"
										size="sm"
										// name={userName}
										name={user?.organization}
										ml={-1}
										mr={2}
									/>
								</Flex>
								<MenuButton
									color={'gray'}
									_hover={{
										color: 'initial',
									}}
									fontSize={'1.5rem'}>
									<MdExpandMore />
								</MenuButton>
							</Flex>
							<MenuList>
								<MenuItem onClick={goToProfile}>
									Profile
								</MenuItem>
								<MenuItem onClick={() => logout(true)}>
									Log out
								</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
					<Flex
						flexDir={'column'}
						align={'start'}
						justify="space-around"
						h="100%"
						zIndex="-1"
						gap={'0.5rem'}>
						{rightBar}
					</Flex>
				</Box>
			</Flex>
		</Flex>
	);
};

export default MainLayout;

import {
	Avatar,
	Box,
	Button,
	Flex,
	Heading,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import {
	MdOutlineArrowForwardIos,
	MdOutlineCancel,
	MdOutlineEmail,
	MdSearch,
} from 'react-icons/md';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { DashboardContext } from '../../context/DashboardContext';
import { MainLayout, SidebarLayout } from '../../layouts';

const Leads = () => {
	const [currentLead, setCurrentLead] = useState();
	const [page, setPage] = useState(1);
	const [searchQuery, setSearchQuery] = useState('');
	const { leads, leadsPageInfo, getProjectLeads } =
		useContext(DashboardContext);

	useEffect(() => {
		getProjectLeads(searchQuery, page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, searchQuery]);

	const handleSearch = async (e) => {
		setSearchQuery(e.target.value);
	};

	const clearSearch = async () => {
		setSearchQuery('');
	};

	const onNextPage = () => {
		setPage((prevState) => ++prevState);
	};

	const onPrevPage = () => {
		if (page !== 1) {
			setPage((prevState) => --prevState);
		}
	};

	const LeadsMain = () => {
		return (
			<Flex flexDir={'column'} gap="3rem">
				<Flex align={'start'} justify={'space-between'}>
					<Flex gap="2rem" align="start" w="full" flexDir={'column'}>
						<Box w="full">
							<Flex justify={'space-between'}>
								<Heading
									fontSize={'6xl'}
									fontWeight="extrabold">
									Your Leads
								</Heading>
								<Link to="/automated-emails">
									<Button colorScheme={'primary'}>
										Automated Emails
									</Button>
								</Link>
							</Flex>
							<Text fontWeight={'light'} color={'gray'}>
								See all the website visitors who might become
								your future customers.
							</Text>
						</Box>
					</Flex>
				</Flex>
				{/*<Flex gap="1rem" flexDir={'column'}>*/}
				{/*	<Text fontSize={'xl'} fontWeight="extrabold">*/}
				{/*		Duration*/}
				{/*	</Text>*/}
				{/*	<Flex*/}
				{/*		p="1rem 2rem"*/}
				{/*// 		borderRadius={'xl'}*/}
				{/*		bg="white"*/}
				{/*		shadow={'sm'}*/}
				{/*		w="full"*/}
				{/*		justify={'space-between'}>*/}
				{/*		<Flex gap={'0.6rem'} align={'center'}>*/}
				{/*			<TbCalendarTime fontSize={'1.8rem'} />*/}
				{/*			<Text fontSize={'sm'}>From</Text>*/}
				{/*			<Input*/}
				{/*				shadow={'base'}*/}
				{/*// 				border={'none'}*/}
				{/*/!*				type={'date'}*!/*/}
				{/*/!*			/>*!/*/}
				{/*// 		</Flex>*/}
				{/*// 		<Flex gap={'0.6rem'} align={'center'}>*/}
				{/*// 			<TbCalendarTime fontSize={'1.8rem'} />*/}
				{/*// 			<Text fontSize={'sm'}>to</Text>*/}
				{/*/!*			<Input*!/*/}
				{/*// 				shadow={'base'}*/}
				{/*// 				border={'none'}*/}
				{/*// 				type={'date'}*/}
				{/*/!*			/>*!/*/}
				{/*/!*		</Flex>*!/*/}
				{/*// 		<Flex gap={'1rem'} align={'center'}>*/}
				{/*// 			<Button*/}
				{/*// 				bgGradient="linear(to-r, #3a5ef0 , #0f2daa )"*/}
				{/*// 				colorScheme={'primary'}>*/}
				{/*// 				Show*/}
				{/*			</Button>*/}
				{/*			<TbDownload fontSize={'1.8rem'} />*/}
				{/*		</Flex>*/}
				{/*	</Flex>*/}
				{/*</Flex>*/}
				<Flex align={'center'}>
					<Text fontSize={'xl'} fontWeight="extrabold" mr="auto">
						All Leads
					</Text>
					<InputGroup w="max-content">
						<InputLeftElement pointerEvents="none">
							<MdSearch fontSize={'1.5rem'} color="gray" />
						</InputLeftElement>
						<Input
							borderRadius={'2rem'}
							fontSize={'lg'}
							type="text"
							placeholder="search"
							value={searchQuery}
							onChange={handleSearch}
						/>
						<InputRightElement>
							{searchQuery && (
								<MdOutlineCancel
									fontSize="1.5rem"
									cursor="pointer"
									onClick={clearSearch}
								/>
							)}
						</InputRightElement>
					</InputGroup>
				</Flex>
				<TableContainer whiteSpace={'wrap'}>
					<Table variant="simple">
						<Thead>
							<Tr>
								<Th>S. no</Th>
								<Th>Date</Th>
								<Th>Name</Th>
								<Th>Email</Th>
								<Th>Source</Th>
								<Th />
							</Tr>
						</Thead>
						<Tbody>
							{leads?.map((lead, index) => (
								<Tr
									key={index}
									backgroundColor={
										currentLead?._id === lead?._id
											? 'primary.500'
											: ''
									}
									color={
										currentLead?._id === lead?._id
											? 'white'
											: ''
									}
									transition="all .5s">
									<Td>{index + 1}</Td>
									<Td>
										<Moment format="ll">
											{lead?.createdAt}
										</Moment>
									</Td>
									<Td>{lead?.name}</Td>
									<Td>{lead?.email}</Td>
									<Td>{lead?.source}</Td>
									<Td>
										{currentLead?._id !== lead?._id ? (
											<IconButton
												icon={
													<MdOutlineArrowForwardIos />
												}
												onClick={() =>
													setCurrentLead(lead)
												}
												colorScheme="primary"
												variant={'ghost'}
												aria-label="select"
											/>
										) : (
											<IconButton
												border={'none'}
												fontSize="1.6rem"
												icon={<MdOutlineCancel />}
												onClick={() =>
													setCurrentLead(null)
												}
												colorScheme="white"
												variant={'outline'}
												aria-label="select"
											/>
										)}
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
				{leads.length > 0 && (
					<Flex align="center" justify="center" gap={4}>
						<Button
							disabled={!leadsPageInfo?.hasPreviousPage}
							onClick={onPrevPage}>
							Prev
						</Button>
						<Text>{page}</Text>
						<Button
							disabled={!leadsPageInfo?.hasNextPage}
							onClick={onNextPage}>
							Next
						</Button>
					</Flex>
				)}
				{/*<Flex gap="1rem" flexDir={'column'}>*/}
				{/*	<Text fontSize={'xl'} fontWeight="extrabold">*/}
				{/*		E-mail Blaster*/}
				{/*	</Text>*/}
				{/*	<Flex*/}
				{/*		p="1rem 2rem"*/}
				{/*		borderRadius={'xl'}*/}
				{/*		bg="white"*/}
				{/*		shadow={'sm'}*/}
				{/*		w="full"*/}
				{/*		justify={'space-between'}>*/}
				{/*		<Flex gap={'0.6rem'} align={'center'}>*/}
				{/*			<Image alt="email" src={email} />*/}
				{/*			<Text>*/}
				{/*				Shoot a correspondence E-mail to all your Apric*/}
				{/*				Leads*/}
				{/*			</Text>*/}
				{/*		</Flex>*/}
				{/*		<Flex gap={'1rem'} align={'center'}>*/}
				{/*			<MdOutlineArrowForward fontSize={'1.8rem'} />*/}
				{/*		</Flex>*/}
				{/*	</Flex>*/}
				{/*</Flex>*/}
			</Flex>
		);
	};

	const LeadsRightBar = () => {
		return (
			<Flex maxH={'80%'} w="100%">
				{!!currentLead && (
					<Flex
						borderRadius={'1rem'}
						shadow="xl"
						w="100%"
						flexDir={'column'}>
						<Flex
							p="1rem"
							color="white"
							borderRadius={'1rem 1rem 0rem 0rem'}
							bg="secondary.500"
							gap={'0.5rem'}
							justify="space-between"
							align="center">
							<Flex gap="0.5rem" align="center">
								<Avatar
									bg="lightgray"
									name={currentLead?.name}
									src="https://bit.ly/tioluwani-kolawole"
								/>
								<Text>{currentLead?.name}</Text>
							</Flex>

							<Box
								fontSize={'2rem'}
								as="a"
								href={`mailto:${currentLead?.email}}`}>
								<MdOutlineEmail />
							</Box>
						</Flex>
						<Flex
							overflow={'scroll'}
							gap="0.5rem"
							p="1rem"
							flexDir={'column'}>
							<Flex
								borderBottom={'1px solid lightgray'}
								flexDir={'column'}>
								<Flex align="center">
									<Text>Email</Text>
								</Flex>
								<Text
									mb="1rem"
									color="primary.500"
									fontSize="xl">
									{currentLead?.email}
								</Text>
							</Flex>
							<Flex
								borderBottom={'1px solid lightgray'}
								flexDir={'column'}>
								<Flex align="center">
									<Text>Source</Text>
								</Flex>
								<Text
									mb="1rem"
									color="primary.500"
									fontSize="xl">
									{currentLead?.source}
								</Text>
							</Flex>
							<Flex
								borderBottom={'1px solid lightgray'}
								flexDir={'column'}>
								<Flex align="center">
									<Text>Contact number</Text>
								</Flex>
								<Text
									mb="1rem"
									color="primary.500"
									fontSize="xl">
									{currentLead?.contact_number}
								</Text>
							</Flex>

							{/* dynamic */}
							{Object.entries(currentLead?.meta_data).map(
								([key, val]) => {
									const updatedKey = key.split('_').join(' ');
									return (
										<Flex
											key={key}
											// borderBottom={'1px solid lightgray'}
											_notLast={{
												borderBottom:
													'1px solid lightgray',
											}}
											flexDir={'column'}>
											<Flex align="center">
												<Text
													textTransform={
														'capitalize'
													}>
													{updatedKey}
												</Text>
											</Flex>
											<Text
												mb="1rem"
												color="primary.500"
												fontSize="xl">
												{val}
											</Text>
										</Flex>
									);
								},
							)}
						</Flex>
					</Flex>
				)}
			</Flex>
		);
	};

	return (
		<SidebarLayout>
			<MainLayout main={LeadsMain()} rightBar={LeadsRightBar()} />
		</SidebarLayout>
	);
};

export default Leads;

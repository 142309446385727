import { Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { MdOutlineChevronRight } from 'react-icons/md';
import table from '../../../assets/sidebar/table.png';

const LatestFromUs = () => {
	return (
		<>
			<Flex flexDir={'column'}>
				<Heading fontSize={'2xl'} fontWeight="semibold">
					Latest from Us
				</Heading>
				<Text fontSize={'sm'} color="gray">
					We are dedicated to make your life better.
				</Text>
			</Flex>
			<a
				target="_blank"
				rel="noreferrer"
				href="https://www.apric.in/blogs/use-color-theory-to-your-advantage">
				<Button
					rightIcon={
						<MdOutlineChevronRight className="cms-button-icon" />
					}
					w="100%"
					fontSize={'sm'}
					colorScheme="secondary"
					variant="solid">
					How can you...
				</Button>
			</a>
			<a
				rel="noreferrer"
				target="_blank"
				href="https://www.apric.in/blogs/minimalism-is-taking-over-the-design-space">
				<Button
					fontSize={'sm'}
					w="100%"
					rightIcon={<MdOutlineChevronRight />}
					colorScheme="secondary"
					variant="solid">
					How Minimalism is ...
				</Button>
			</a>
			<Flex w="full" justify={'center'} align="center">
				<Image w="90%" src={table} />
			</Flex>
		</>
	);
};

export default LatestFromUs;

import { Flex, Heading, IconButton, Text } from '@chakra-ui/react';
import React from 'react';
import { BiEdit, BiPlusCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const YourShortcuts = () => {
	return (
		<>
			<Flex flexDir={'column'}>
				<Heading fontSize={'2xl'} fontWeight="semibold">
					Your Shortcuts
				</Heading>
				<Text fontSize={'sm'} color="gray">
					Tailored based on your usage.
				</Text>
			</Flex>
			<Link to="/create-blog">
				<Flex align="center" gap={'0.5rem'}>
					<IconButton
						bgGradient="linear(to-r, #3a5ef0 , #0f2daa )"
						size="sm"
						colorScheme="primary"
						aria-label="Search database"
						icon={<BiEdit fontSize={'1.2rem'} />}
					/>
					<Text fontSize={'sm'} color="gray">
						Publish a Blog
					</Text>
				</Flex>
			</Link>
			<Link to="/create-blog">
				<Flex align="center" gap={'0.5rem'}>
					<IconButton
						bgGradient="linear(to-r, #3a5ef0 , #0f2daa )"
						size="sm"
						colorScheme="primary"
						aria-label="Search database"
						icon={<BiPlusCircle fontSize={'1.2rem'} />}
					/>
					<Text fontSize={'sm'} color="gray">
						Add a New Product
					</Text>
				</Flex>
			</Link>
		</>
	);
};

export default YourShortcuts;

import {
	Button,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { SectionHeadings } from '../../components';
import { AuthContext } from '../../context/Auth.Context';
import ProfileSidebarLayout from '../../layouts/ProfileSidebarLayout';
import { BiCheck, BiLock } from 'react-icons/bi';

const Profile = () => {
	const {
		currentProject,
		user,
		updateCurrentProject,
		updateMe,
		userLoading,
	} = useContext(AuthContext);
	const [userData, setUserData] = useState();
	const [projectData, setProjectData] = useState();
	const [isSaveUserClicked, setIsSaveUserClicked] = useState(false);

	useEffect(() => {
		setUserData(user);
	}, [user]);

	useEffect(() => {
		setProjectData(currentProject);
	}, [currentProject]);

	const handleUserDataChange = (e) => {
		setUserData((prevUser) => ({
			...prevUser,
			[e.target.name]: e.target.value,
		}));
	};

	const handleProjectDataChange = (e) => {
		setProjectData((prevProject) => ({
			...prevProject,
			[e.target.name]: e.target.value,
		}));
	};

	const saveUser = async () => {
		console.log(userData);
		await setIsSaveUserClicked(true);
		await updateMe(userData);
		await setIsSaveUserClicked(false);
	};

	const saveProject = async () => {
		console.log(projectData);
		await updateCurrentProject(projectData);
	};

	return (
		<ProfileSidebarLayout>
			<SectionHeadings title="Profile Details">
				<FormControl>
					<FormLabel>Name</FormLabel>
					<InputGroup>
						<Input
							placeholder="name"
							type="text"
							name="name"
							defaultValue={user?.name}
							onChange={handleUserDataChange}
						/>
						<InputRightElement
							children={<BiCheck color="green.500" />}
						/>
					</InputGroup>
				</FormControl>
				<FormControl>
					<FormLabel>Organization</FormLabel>
					<InputGroup>
						<Input
							placeholder="Enter organization"
							type="text"
							name="organization"
							defaultValue={user?.organization}
							onChange={handleUserDataChange}
						/>
						<InputRightElement
							children={<BiCheck color="green.500" />}
						/>
					</InputGroup>
				</FormControl>
				<FormControl>
					<FormLabel>Email</FormLabel>
					<InputGroup>
						<Input
							placeholder="Enter email"
							type="email"
							name="email"
							defaultValue={user?.email}
							disabled
						/>
						<InputRightElement children={<BiLock />} />
					</InputGroup>
				</FormControl>
				<FormControl>
					<FormLabel>Role</FormLabel>
					<InputGroup>
						<Input
							placeholder="Select Role"
							type="text"
							name="role"
							defaultValue={user?.type}
							disabled
						/>
						<InputRightElement children={<BiLock />} />
					</InputGroup>
				</FormControl>
				<Button
					isLoading={isSaveUserClicked && userLoading}
					colorScheme="primary"
					mb={6}
					onClick={saveUser}>
					Save
				</Button>
			</SectionHeadings>
			<SectionHeadings title="Project Details">
				<FormControl>
					<FormLabel>Name</FormLabel>
					<InputGroup>
						<Input
							placeholder="name"
							type="text"
							name="name"
							defaultValue={currentProject?.name}
							onChange={handleProjectDataChange}
						/>
						<InputRightElement
							children={<BiCheck color="green.500" />}
						/>
					</InputGroup>
				</FormControl>
				<FormControl>
					<FormLabel>Project URL</FormLabel>
					<InputGroup>
						<Input
							placeholder="Enter Project URL"
							type="text"
							name="website_url"
							defaultValue={currentProject?.website_url}
							onChange={handleProjectDataChange}
						/>
						<InputRightElement
							children={<BiCheck color="green.500" />}
						/>
					</InputGroup>
				</FormControl>
				<FormControl>
					<FormLabel>Unique Token</FormLabel>
					<InputGroup>
						<Input
							type="text"
							name="unique_token"
							defaultValue={currentProject?.unique_token}
							disabled
						/>
						<InputRightElement children={<BiLock />} />
					</InputGroup>
				</FormControl>
				<Button
					isLoading={!isSaveUserClicked && userLoading}
					colorScheme="primary"
					mb={10}
					onClick={saveProject}>
					Save
				</Button>
			</SectionHeadings>
		</ProfileSidebarLayout>
	);
};

export default Profile;

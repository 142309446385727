import { Button, Flex, Text } from '@chakra-ui/react';
import { HiOutlineRefresh } from 'react-icons/hi';

const MetaDataBar = () => {
	return (
		<>
			<Flex justify={'space-between'} gap={'1rem'}>
				<Flex
					color={'#ffffff'}
					shadow={'base'}
					align={'center'}
					bg={'#F48668'}
					borderRadius={'lg'}
					w="80%"
					fontWeight={'bold'}
					justify="space-around">
					<Text fontSize="xl">Created : 15-07-2022</Text>
					<Text fontSize="xl">Last Modified : 18-07-2022</Text>
					<Text fontSize="xl">Version : 1</Text>
					<Text fontSize="xl">
						Status :{' '}
						<Text as={'span'} color="green.300">
							●
						</Text>{' '}
						Live
					</Text>
				</Flex>
				<Flex>
					<Button
						leftIcon={<HiOutlineRefresh />}
						bg={'#3A5DEF'}
						colorScheme={'primary'}
						border={'1px solid lightgray'}
						size="lg">
						Publish Changes
					</Button>
				</Flex>
			</Flex>
		</>
	);
};

export default MetaDataBar;

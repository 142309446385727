import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ Components }) => {
	const navigate = useNavigate();

	useEffect(() => {
		const token = localStorage.getItem('token');

		if (!token) {
			navigate('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Components />;
};

export default ProtectedRoute;

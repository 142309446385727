import {
	Button,
	Flex,
	Heading,
	Image,
	SimpleGrid,
	Text,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import noServiceCategory from '../../assets/servicecategorypage/noData.png';
import { DashboardContext } from '../../context/DashboardContext';
import { MainLayout, SidebarLayout } from '../../layouts';
import LatestFromUs from '../Dashboard/components/LatestFromUs';
import Stats from '../WebsiteProducts/components/Stats';
import blogs_logo from '../../assets/rightBar/blogs.png';
import ServiceCategoryCard from './components/ServiceCategoryCard';

const ServiceCategoryList = () => {
	const { serviceCategories, getServiceCategories, serviceCategoryCount } =
		useContext(DashboardContext);

	useEffect(() => {
		getServiceCategories();
	}, []);

	const ServiceListMain = () => {
		return (
			<Flex flexDir={'column'} gap="3rem">
				<Flex align={'start'} justify={'space-between'}>
					<Flex flexDir={'column'}>
						<Heading fontSize={'6xl'} fontWeight="extrabold">
							Service Categories
						</Heading>
						<Text fontWeight={'light'} color={'gray'}>
							Create new service category here
						</Text>
					</Flex>
					<Link to={`/create-servicecategory`}>
						<Button
							bgGradient="linear(to-r, #3a5ef0 , #0f2daa)"
							colorScheme="primary"
							size="md">
							Create New Service Category&nbsp;&nbsp;+
						</Button>
					</Link>
				</Flex>
				<Flex align={'center'}>
					<Text fontSize={'xl'} fontWeight="extrabold" mr="auto">
						All Service Categories
					</Text>
				</Flex>

				{serviceCategories.length < 1 && (
					<Flex>
						<Image
							w="80%"
							alt="no service category image"
							src={noServiceCategory}
						/>
					</Flex>
				)}

				<SimpleGrid columns={2} spacing={10}>
					{serviceCategories.length > 0 &&
						serviceCategories.map((serviceCategory, index) => (
							// <Link to={`/service-list/${serviceCategory._id}`}>
							<div key={index}>
								<ServiceCategoryCard data={serviceCategory} />
							</div>
							// </Link>
						))}
				</SimpleGrid>
			</Flex>
		);
	};

	const ServiceListMainRightBar = () => {
		return (
			<Flex h="100%" justify={'space-evenly'} flexDir={'column'}>
				<Flex gap="1rem" flexDir={'column'}>
					<Heading fontSize={'2xl'} fontWeight="semibold">
						Stats
					</Heading>
					<Flex flexDir={'column'} gap={'1rem'} w="100%">
						<Stats
							image={blogs_logo}
							count={serviceCategoryCount}
							statsHeading="Available Service Categories"
						/>
					</Flex>
				</Flex>
				<Flex gap="1rem" flexDir={'column'}>
					<LatestFromUs />
				</Flex>
			</Flex>
		);
	};

	return (
		<SidebarLayout>
			<MainLayout
				main={ServiceListMain()}
				rightBar={ServiceListMainRightBar()}
			/>
		</SidebarLayout>
	);
};

export default ServiceCategoryList;

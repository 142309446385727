import { Flex } from '@chakra-ui/react';
import React from 'react';

const SectionHeadings = ({ title, children }) => {
	return (
		<Flex gap={'2rem'} flexDir="column" my={4}>
			<Flex fontWeight={'semibold'} fontSize={'xl'}>
				{title}{' '}
			</Flex>
			{children}
		</Flex>
	);
};

export default SectionHeadings;

import { Box, Button, Flex, Image, Link, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import logo from '../../assets/navbar/blue-logo.svg';
import { getGoogleRedirectUrl } from '../../services/requests';

const Signup = () => {
	const [googleAuthUrl, setGoogleAuthUrl] = useState('');

	const getUrl = async () => {
		const res = await getGoogleRedirectUrl();
		setGoogleAuthUrl(res.data);
	};

	useEffect(() => {
		getUrl();
	}, []);

	return (
		<Flex direction={{ base: 'column', lg: 'row' }}>
			<Flex
				align="center"
				justify="center"
				w={{ base: '100%', lg: '50%' }}>
				<Box
					position="relative"
					w={{ base: '100%', lg: '50%' }}
					p="8"
					borderRadius="5"
					textAlign="center">
					<Flex justify="center" align="center">
						<Image alt="logo" src={logo} />
					</Flex>
					<Text fontSize="2X2" my="1.5rem" color="#b3b3b3">
						Welcome back to your creative zone!
					</Text>
					<a href={googleAuthUrl}>
						<Button
							my={5}
							colorScheme="primary"
							shadow="primaryBtn"
							type="submit">
							<b>SignUp with Google</b>
						</Button>{' '}
					</a>
					<Text
						display={{ base: 'block', lg: 'none' }}
						align="center"
						justifySelf="flex-end"
						marginTop="3rem">
						Product of{' '}
						<b>
							<Link href="https://Apric.in" isExternal>
								Apric
							</Link>
						</b>
					</Text>
				</Box>
			</Flex>
			<Flex
				overflow="hidden"
				position="relative"
				h={{ base: '50vh', lg: '100vh' }}
				w={{ base: '100%', lg: '50%' }}
				// h={{ base: "100%", md: "50%" }}
			>
				<Image
					height="100%"
					width="100%"
					objectFit="cover"
					src="https://apric-web.s3.us-east-2.amazonaws.com/assets/images/Apric+Link+Creatives-03.jpeg"
					alt="webx logo"
				/>
				<Image
					position="absolute"
					top="0"
					left="50%"
					w={{ base: '25%', lg: 'initial' }}
					transform={{
						base: 'translate(-50%, 10vh)',
						lg: 'translate(-50%, 15vh)',
					}}
					align="center"
					justifySelf="flex-end"
					objectFit="cover"
					src="https://apric-web.s3.us-east-2.amazonaws.com/assets/images/login_blue-logo.svg"
					alt="webx logo"
				/>
				<Text
					display={{ base: 'none', lg: 'block' }}
					position="absolute"
					bottom="0"
					left="50%"
					transform="translate(-50%, 0%)"
					align="center"
					justifySelf="flex-end"
					padding="1rem"
					color="white">
					Product of{' '}
					<b>
						<Link href="https://Apric.in" isExternal>
							Apric
						</Link>
					</b>
				</Text>
			</Flex>
		</Flex>
	);
};

export default Signup;

import { Badge, Box, Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdOutlineEast } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const BlogCards = ({ data }) => {
	const { blog_cover_image, blog_title, createdAt, _id, blog_status } = data;
	const navigate = useNavigate();

	const openBlog = (id) => {
		navigate(`/blogs-list/${id}`);
	};

	const openEditBlog = (id) => {
		navigate(`/edit-blog/${id}`);
	};

	return (
		<Flex borderRadius={'1rem'} p="1rem" gap="1rem" bg="white">
			<Box
				borderRadius={'0.5rem'}
				boxSize={'6rem'}
				background={`url(${blog_cover_image}) center/cover no-repeat`}
			/>
			<Flex flexDir={'column'} justify="space-around" flex={'3'}>
				<Box>
					<Badge
						size="xs"
						variant="subtle"
						colorScheme="green"
						textTransform={'capitalize'}
						borderRadius="full">
						{blog_status}
					</Badge>
					<Text fontSize={'sm'}>{blog_title}</Text>
				</Box>
				<Text color="gray" fontSize={'xs'}>
					Last updated - {moment(createdAt).format('ll')}
				</Text>
			</Flex>
			<Flex
				flexDir={'column'}
				align="end"
				justify="space-between"
				flex={'1'}>
				<Box
					cursor={'pointer'}
					color="gray.400"
					onClick={() => openEditBlog(_id)}
					fontSize={'1.5rem'}
					_hover={{
						color: 'gray.900',
					}}>
					<AiOutlineEdit />
				</Box>
				<Box
					cursor={'pointer'}
					color="gray.400"
					onClick={() => openBlog(_id)}
					fontSize={'1.5rem'}
					_hover={{
						color: 'gray.900',
					}}>
					<MdOutlineEast />
				</Box>
			</Flex>
		</Flex>
	);
};

export default BlogCards;

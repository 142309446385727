import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Login, ProtectedRoute, Signup } from './auth';
import GoogleAuthLogin from './auth/GoogleAuthLogin';
import {
	Analytics,
	BlogsList,
	ServiceCategoryList,
	ServicesList,
	Dashboard,
	Pages,
	PageSection,
	WebsiteProducts,
} from './pages';

import CreateBlog from './pages/Blog/CreateBlog';
import ViewBlog from './pages/Blog/ViewBlog';
import Leads from './pages/Leads/Leads';
import Bookings from './pages/Bookings/Bookings';
import Profile from './pages/Profile/Profile';
import WebsitePages from './pages/WebsitePages/WebsitePages';
import AuthProvider from './context/Auth.Context';
import ErrorBoundary from './pages/ErrorBoundary/ErrorBoundary';
import AddProduct from './pages/AddProduct/AddProduct';
import DashboardProvider from './context/DashboardContext';
import EditProduct from './pages/EditProduct/EditProduct';
import EditBlog from './pages/EditBlog/EditBlog';
import CreateServiceCategory from './pages/ServicePage/CreateServiceCategory';
import CreateService from './pages/ServicePage/CreateService';
import AutomatedEmails from './pages/AutomatedEmails/AutomatedEmails';
import EditServiceCategory from './pages/EditServiceCategory/EditServiceCategory';
import EditService from './pages/EditService/EditService';

function App() {
	return (
		<>
			<ErrorBoundary>
				<BrowserRouter>
					<AuthProvider>
						<DashboardProvider>
							<Routes>
								<Route path="*/*" element={<Login />} />
								<Route path="/" element={<Login />} />
								<Route path="/signup" element={<Signup />} />
								<Route
									path="/auth/google/callback"
									element={<GoogleAuthLogin />}
								/>
								<Route
									path="dashboard"
									element={
										<ProtectedRoute
											Components={Dashboard}
										/>
									}
								/>
								<Route
									path="profile"
									element={
										<ProtectedRoute Components={Profile} />
									}
								/>
								<Route
									path="pages"
									element={
										<ProtectedRoute
											Components={WebsitePages}
										/>
									}
								/>
								<Route
									path="pages/:id"
									element={
										<ProtectedRoute Components={Pages} />
									}
								/>
								<Route
									path="create-blog"
									element={
										<ProtectedRoute
											Components={CreateBlog}
										/>
									}
								/>
								<Route
									path="categoryservice-list"
									element={
										<ProtectedRoute
											Components={ServiceCategoryList}
										/>
									}
								/>
								<Route
									path="service-list/:serviceCategoryId"
									element={
										<ProtectedRoute
											Components={ServicesList}
										/>
									}
								/>
								<Route
									path="create-service/:serviceCategoryId"
									element={
										<ProtectedRoute
											Components={CreateService}
										/>
									}
								/>
								<Route
									path="create-servicecategory"
									element={
										<ProtectedRoute
											Components={CreateServiceCategory}
										/>
									}
								/>
								{/* Added by Hemlata to edit service category functionlaity on 7 Feb 2024*/}
								<Route
									path="edit-servicecategory/:id"
									element={
										<ProtectedRoute
											Components={EditServiceCategory}
										/>
									}
								/>
								<Route
									path="edit-service/:id"
									element={
										<ProtectedRoute
											Components={EditService}
										/>
									}
								/>
								<Route
									path="website-products"
									element={
										<ProtectedRoute
											Components={WebsiteProducts}
										/>
									}
								/>
								<Route
									path="website-products/add-product"
									element={
										<ProtectedRoute
											Components={AddProduct}
										/>
									}
								/>
								<Route
									path="website-products/edit-product/:id"
									element={
										<ProtectedRoute
											Components={EditProduct}
										/>
									}
								/>
								<Route
									path="leads"
									element={
										<ProtectedRoute Components={Leads} />
									}
								/>
								<Route
									path="bookings"
									element={
										<ProtectedRoute Components={Bookings} />
									}
								/>
								<Route
									path="blogs-list"
									element={
										<ProtectedRoute
											Components={BlogsList}
										/>
									}
								/>
								<Route
									path="blogs-list/:id"
									element={
										<ProtectedRoute Components={ViewBlog} />
									}
								/>
								<Route
									path="edit-blog/:id"
									element={
										<ProtectedRoute Components={EditBlog} />
									}
								/>
								<Route
									path="website-analytics"
									element={
										<ProtectedRoute
											Components={Analytics}
										/>
									}
								/>
								<Route
									path="/website-space/:pageId/:sectionId"
									element={<PageSection />}
								/>
								<Route
									path="/automated-emails"
									element={<AutomatedEmails />}
								/>
							</Routes>
						</DashboardProvider>
					</AuthProvider>
				</BrowserRouter>
			</ErrorBoundary>
		</>
	);
}

export default App;

import { Box, Flex, Image, Text } from '@chakra-ui/react';

import React from 'react';

const OnBoardingCard = ({ count, image, name }) => {
	return (
		<Box flex="1" background="#FFFFFF" borderRadius="20px" p="0.5rem 2rem">
			<Flex align="center" justify={'space-between'}>
				<Flex h="100%" flexDir={'column'} justify={'space-between'}>
					<Text fontSize="2xl">Number of {name}</Text>
					<Text fontSize="6xl" fontWeight="bold">
						{count}
					</Text>
				</Flex>
				<Box>
					<Image
						transform={name === 'Blogs' && 'scale(1.3)'}
						objectFit="contain"
						src={image}
						alt={image}
						w="8rem"
						h="8rem"
					/>
				</Box>
			</Flex>
		</Box>
	);
};

export default OnBoardingCard;

import { Box } from '@chakra-ui/react';

const EditPagesCardLayout = ({ children }) => {
	return (
		<Box
			as="div"
			display={'flex'}
			flexDir={'column'}
			justifyContent={'space-between'}
			borderRadius="1rem"
			bg="#ffffff"
			shadow={'lg'}
			w="13.625rem"
			h="15.5rem">
			{children}
		</Box>
	);
};

export default EditPagesCardLayout;

import { FiShoppingBag } from 'react-icons/fi';
import landing from '../assets/images/NiwiHome.png';
import about from '../assets/images/NiwiAbout.png';
import niwiexperience from '../assets/images/niwiexperience.png';
import niwiprogramme from '../assets/images/niwiprogramme.png';
import niwiblogs from '../assets/images/niwiblogs.png';

export const links = [
	{
		title: 'Website name',
		steps: [
			{
				id: '1',
				title: 'Plan',
				icon: <FiShoppingBag />,
			},
			{
				id: '2',
				title: 'Design',
				icon: <FiShoppingBag />,
			},

			{
				id: '3',
				title: 'Manage',
				icon: <FiShoppingBag />,
				pages: [
					{
						id: '4',
						title: 'Analytics',
						icon: <FiShoppingBag />,
					},
					{
						id: '5',
						title: 'Pages',
						icon: <FiShoppingBag />,
					},
					{
						id: '6',
						title: 'Products',
						icon: <FiShoppingBag />,
					},
					{
						id: '7',
						title: 'Blogs',
						icon: <FiShoppingBag />,
					},
				],
			},
		],
	},
];

export const getWebsiteData = [
	{
		name: 'Niwi Website',
		from: 'website',
		number: 1995,
		amount: '$10,800',
		due: '12/05/1995',
		img: landing,
	},
];

export const getPageData = [
	{
		name: 'Home',
		from: 'pages',
		number: 1995,
		amount: '$10,800',
		due: '12/05/1995',
		img: landing,
	},
	{
		name: 'About',
		number: 2000,
		amount: '$8,000',
		due: '10/31/2000',
		img: about,
	},
	{
		name: 'Diet Programmes',
		number: 2003,
		amount: '$9,500',
		due: '07/22/2003',
		img: niwiprogramme,
	},
	{
		name: 'n.experience',
		number: 1997,
		amount: '$14,000',
		due: '09/01/1997',
		img: niwiexperience,
	},
	{
		name: 'Blogs',
		number: 1998,
		amount: '$4,600',
		due: '01/27/1998',
		img: niwiblogs,
	},
];

export const getPageSections = [
	{
		title: 'Hero Section',
	},
	{
		title: 'You can relate',
	},
	{
		title: 'Features',
	},
	{
		title: 'Niwi Quote',
	},
	{
		title: 'Why Niwi?',
	},
	{
		title: 'Happy Clients',
	},
	{
		title: 'How to start',
	},
];

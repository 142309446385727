import {
	Box,
	Button,
	CloseButton,
	Flex,
	FormControl,
	Heading,
	Input,
	Text,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GiQuillInk } from 'react-icons/gi';
import {
	MdLabelImportantOutline,
	MdOutlineImageSearch,
	MdOutlineKeyboardAlt,
	MdOutlineTextsms,
	MdTitle,
} from 'react-icons/md';
import { RichTextEditor, SectionHeadings } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/Auth.Context';
import { MainLayout, SidebarLayout } from '../../layouts';
import { getBlogById, getImageUrl, sendImage } from '../../services/requests';
import LatestFromUs from '../Dashboard/components/LatestFromUs';
import { DashboardContext } from '../../context/DashboardContext';
import GoBack from '../../shared/GoBack';

const CreateBlog = () => {
	const [title, setTitle] = useState('');
	const [blogCategory, setBlogCategory] = useState('');
	const [slug, setSlug] = useState('');
	const [coverImage, setCoverImage] = useState('');
	const [authorName, setAuthorName] = useState('');
	const [content, setContent] = useState('');
	const [metaTitle, setMetaTitle] = useState('');
	const [metaDescription, setMetaDescription] = useState('');
	const [metaImage, setMetaImage] = useState('');
	const [metaKeywords, setMetaKeywords] = useState('');
	const [metaImageDisplayUrl, setMetaImageDisplayUrl] = useState('');
	const [blogImageDisplayUrl, setBlogImageDisplayUrl] = useState('');
	// const [nextBlogSlug, setNextBlogSlug] = useState('');
	// const [blogStatus, setBlogStatus] = useState('');

	const { updateBlog } = useContext(DashboardContext);
	const { currentProject } = useContext(AuthContext);

	const params = useParams();

	useEffect(() => {
		getBlog().then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentProject]);

	const getBlog = async () => {
		const id = params.id;
		const uniqueToken = currentProject?.unique_token;
		if (!!uniqueToken) {
			const res = await getBlogById(uniqueToken, id);
			setTitle(res?.doc?.blog_title || '');
			setBlogCategory(res?.doc?.blog_category || '');
			setSlug(res?.doc?.blog_slug || '');
			setCoverImage(res?.doc?.blog_cover_image || '');
			setBlogImageDisplayUrl(res?.doc?.blog_cover_image || '');
			setAuthorName(res?.doc?.author_name || '');
			setContent(res?.doc?.blog_content || '');
			setMetaTitle(res?.doc?.meta_title || '');
			setMetaDescription(res?.doc?.meta_description || '');
			setMetaImage(res?.doc?.meta_image || '');
			setMetaImageDisplayUrl(res?.doc?.meta_image || '');
			setMetaKeywords(res?.doc?.meta_keywords || '');
		}
	};

	const selectMetaInputRef = useRef(null);

	const selectBlogInputRef = useRef(null);

	const selectMetaImage = () => {
		selectMetaInputRef.current.click();
	};

	const selectBlogImage = () => {
		selectBlogInputRef.current.click();
	};

	const removeMetaImage = () => {
		setMetaImageDisplayUrl('');
		setMetaImage('');
	};

	const removeBlogImage = () => {
		setBlogImageDisplayUrl('');
		setCoverImage('');
	};

	const uploadMetaImage = async (e) => {
		const file = e.target.files[0];
		if (!!file) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = (res) => {
				setMetaImageDisplayUrl(res.target.result);
			};
			const res = await uploadImage(file);
			setMetaImage(res);
		}
	};

	const uploadBlogImage = async (e) => {
		const file = e.target.files[0];
		if (!!file) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = (res) => {
				setBlogImageDisplayUrl(res.target.result);
			};
			const res = await uploadImage(file);
			setCoverImage(res);
		}
	};

	const uploadImage = async (file) => {
		const name = file.name;
		const type = file.type;
		const uniqueToken = currentProject?.unique_token;
		const { data: url } = await getImageUrl(type, name, uniqueToken);
		const res = await sendImage(url, file);
		const fileUrl = url.split('?')[0];
		if (res.status === 200) {
			return fileUrl;
		}
	};

	const navigate = useNavigate();

	const handleSave = async (e) => {
		e.preventDefault();
		const id = params.id;
		const payload = {
			blog_title: title,
			blog_category: blogCategory,
			blog_slug: slug,
			blog_cover_image: coverImage,
			author_name: authorName,
			blog_content: content,
			meta_title: metaTitle,
			meta_description: metaDescription,
			meta_image: metaImage,
			meta_keywords: metaKeywords,
			// next_blog_slug: nextBlogSlug,
			blog_status: 'Draft',
		};
		const res = await updateBlog(payload, id);
		if (res === 'success') {
			navigate('/blogs-list');
		}
	};

	const CreateBlogMain = () => {
		return (
			<Flex flexDir={'column'} gap="3rem">
				<Flex
					pos={'sticky'}
					top="0"
					p="0.5rem 0"
					bg="mainBg"
					zIndex={'overlay'}>
					<GoBack />
				</Flex>

				<Flex align={'start'} justify={'space-between'}>
					<Flex flexDir={'column'} align={'start'} gap="1rem">
						<Box>
							<Heading fontSize={'6xl'} fontWeight="extrabold">
								New Blog
							</Heading>
							<Text fontWeight={'light'} color={'gray'}>
								Let your creativity flow here
							</Text>
						</Box>
					</Flex>
				</Flex>
				<Flex flexDir={'column'} gap={'4rem'}>
					<SectionHeadings title="Publishing Details">
						<FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdTitle fontSize={'1.5rem'} />
									<Text>Blog Title</Text>
								</Flex>
								<Input
									placeholder="blog title"
									w="80%"
									type="email"
									value={title}
									onChange={(e) => setTitle(e.target.value)}
								/>
							</Flex>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<GiQuillInk fontSize={'1.5rem'} />
									<Text>Author Name</Text>
								</Flex>
								<Input
									placeholder="Author Name"
									w="80%"
									type="text"
									value={authorName}
									onChange={(e) =>
										setAuthorName(e.target.value)
									}
								/>
							</Flex>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdOutlineTextsms fontSize={'1.5rem'} />
									<Text>Blog Category</Text>
								</Flex>
								<Input
									placeholder="blog category"
									w="80%"
									type="text"
									value={blogCategory}
									onChange={(e) =>
										setBlogCategory(e.target.value)
									}
								/>
							</Flex>
						</FormControl>
					</SectionHeadings>

					<SectionHeadings title="SEO Details">
						<FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdTitle fontSize={'1.5rem'} />
									<Text>Title Slug</Text>
								</Flex>
								<Input
									placeholder="Title Slug"
									w="80%"
									type="text"
									value={slug}
									onChange={(e) => setSlug(e.target.value)}
								/>
							</Flex>
						</FormControl>
						<FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdLabelImportantOutline
										fontSize={'1.5rem'}
									/>
									<Text>Meta Title</Text>
								</Flex>
								<Input
									placeholder="Meta Title"
									w="80%"
									type="text"
									value={metaTitle}
									onChange={(e) =>
										setMetaTitle(e.target.value)
									}
								/>
							</Flex>
						</FormControl>
						<FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdOutlineTextsms fontSize={'1.5rem'} />
									<Text>Meta Description</Text>
								</Flex>
								<Input
									placeholder="Meta Description"
									w="80%"
									type="text"
									value={metaDescription}
									onChange={(e) =>
										setMetaDescription(e.target.value)
									}
								/>
							</Flex>
						</FormControl>
						<FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdOutlineKeyboardAlt fontSize={'1.5rem'} />
									<Text>Keywords</Text>
								</Flex>
								<Input
									placeholder="Keywords"
									w="80%"
									type="text"
									value={metaKeywords}
									onChange={(e) =>
										setMetaKeywords(e.target.value)
									}
								/>
							</Flex>
						</FormControl>
						<FormControl
							display={'flex'}
							flexDir="column"
							gap={'1rem'}
							w="100%"
							align={'center'}>
							<Flex w="100%">
								<Flex gap={'0.5rem'} align={'center'} w="20%">
									<MdOutlineImageSearch fontSize={'1.5rem'} />
									<Text>Meta Image</Text>
								</Flex>
								<input
									style={{ display: 'none' }}
									ref={selectMetaInputRef}
									accept="image/jpeg, image/png"
									onChange={uploadMetaImage}
									type="file"
								/>
								{!metaImageDisplayUrl && (
									<Button
										w="max-content"
										colorScheme={'primary'}
										onClick={selectMetaImage}>
										+ Add Image
									</Button>
								)}
								{/*<input*/}
								{/*	type={'file'}*/}
								{/*	onChange={uploadMetaImage}*/}
								{/*/>*/}
							</Flex>
						</FormControl>
						{!!metaImageDisplayUrl && (
							<Box
								w="50%"
								pos="relative"
								border={'1px dashed gray'}
								p="4rem 1rem 1rem 1rem">
								<img src={metaImageDisplayUrl} alt="selected" />
								<CloseButton
									onClick={removeMetaImage}
									colorScheme={'primary'}
									pos={'absolute'}
									top="1rem"
									right={'1rem'}
								/>
							</Box>
						)}
					</SectionHeadings>

					<SectionHeadings title="Blog Image">
						<input
							style={{ display: 'none' }}
							ref={selectBlogInputRef}
							accept="image/jpeg, image/png"
							onChange={uploadBlogImage}
							type="file"
						/>
						{!blogImageDisplayUrl && (
							<Button
								w="max-content"
								colorScheme={'primary'}
								onClick={selectBlogImage}>
								+ Add Image
							</Button>
						)}
						{!!blogImageDisplayUrl && (
							<Box
								w="50%"
								pos="relative"
								border={'1px dashed gray'}
								p="4rem 1rem 1rem 1rem">
								<img src={blogImageDisplayUrl} alt="selected" />
								<CloseButton
									onClick={removeBlogImage}
									colorScheme={'primary'}
									pos={'absolute'}
									top="1rem"
									right={'1rem'}
								/>
							</Box>
						)}
						{/*<input type={'file'} onChange={uploadBlogImage} />*/}
					</SectionHeadings>
					<SectionHeadings title="Blog Content">
						<RichTextEditor
							// onBlogContentChange={(e) =>
							// 	setContent(e?.target?.value)
							// }
							onBlogContentChange={setContent}
							blogContent={content}
						/>
					</SectionHeadings>
					{/*<SectionHeadings title="CTA Button">*/}
					{/*//     <FormControl*/}
					{/*//         display={'flex'}*/}
					{/*//         flexDir="column"*/}
					{/*//         gap={'1rem'}*/}
					{/*//         w="100%"*/}
					{/*//         align={'center'}>*/}
					{/*//         <Flex w="100%">*/}
					{/*//             <Flex gap={'0.5rem'} align={'center'} w="20%">*/}
					{/*/!*                <MdLink fontSize={'1.5rem'} />*!/*/}
					{/*//                 <Text>Button Link</Text>*/}
					{/*//             </Flex>*/}
					{/*//             <Input*/}
					{/*//                 placeholder="button text"*/}
					{/*//                 w="80%"*/}
					{/*//                 type="email"*/}
					{/*//             />*/}
					{/*//         </Flex>*/}
					{/*        <Flex w="100%">*/}
					{/*            <Flex gap={'0.5rem'} align={'center'} w="20%">*/}
					{/*                <MdLabelImportantOutline*/}
					{/*                    fontSize={'1.5rem'}*/}
					{/*                />*/}
					{/*                <Text>Button Text</Text>*/}
					{/*            </Flex>*/}
					{/*            <Input*/}
					{/*                placeholder="button text"*/}
					{/*                w="80%"*/}
					{/*                type="email"*/}
					{/*            />*/}
					{/*        </Flex>*/}
					{/*    </FormControl>*/}
					{/*</SectionHeadings>*/}
				</Flex>
				<Flex
					w="full"
					p="0.5rem 0"
					bottom={'0'}
					pos={'sticky'}
					bg="#F5F5F5"
					mb={'2rem'}
					align={'center'}
					gap="1rem"
					justify={'end'}>
					<Button
						type="submit"
						colorScheme="primary"
						size="md"
						onClick={handleSave}>
						Save blog
					</Button>
					<Button type="submit" colorScheme="primary" size="md">
						Publish blog
					</Button>
				</Flex>
			</Flex>
		);
	};

	const CreateBlogRightBar = () => {
		return (
			<>
				<Flex h="100%" justify={'space-evenly'} flexDir={'column'}>
					<Flex gap="1rem" flexDir={'column'}>
						{/* Future Component to be.. */}
					</Flex>
					<Flex gap="1rem" flexDir={'column'}>
						<LatestFromUs />
					</Flex>
				</Flex>
			</>
		);
	};

	return (
		<SidebarLayout>
			<MainLayout
				main={CreateBlogMain()}
				rightBar={CreateBlogRightBar()}
			/>
		</SidebarLayout>
	);
};

export default CreateBlog;

import { Box } from '@chakra-ui/react';

const CardLayout = ({ children }) => {
	return (
		<Box
			as="div"
			display={'flex'}
			flexDir={'column'}
			justifyContent={'space-between'}
			border={'1px #91AAEE'}
			borderRadius="1rem"
			shadow={'lg'}
			bg="primary.50"
			w="13.625rem"
			h="15.5rem">
			{children}
		</Box>
	);
};

export default CardLayout;

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { AuthContext } from '../../context/Auth.Context';
import { getImageUrl, sendImage } from '../../services/requests';

const RichTextEditor = ({ onBlogContentChange, blogContent }) => {
	const quillRef = useRef();
	const [value, setValue] = useState('');
	const { currentProject } = useContext(AuthContext);

	useEffect(() => {
		setValue(blogContent || '');
	}, [blogContent]);

	const uploadImage = async (file) => {
		const name = file.name;
		const type = file.type;
		const uniqueToken = currentProject?.unique_token;
		const { data: url } = await getImageUrl(type, name, uniqueToken);
		const res = await sendImage(url, file);
		const fileUrl = url.split('?')[0];
		if (res.status === 200) {
			return fileUrl;
		}
	};

	const imageHandler = async () => {
		let quillObj = quillRef.current.getEditor();
		const range = quillObj.getSelection();
		// const url = prompt('Enter image URL');
		// if (url) {
		// 	quillObj.editor.insertEmbed(range.index, 'image', url);
		// 	quillObj.setSelection(range.index + 1);
		// 	onBlogContentChange(quillObj.getContents());
		// } else {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.setAttribute('max-size', '2000');
		input.click();
		input.onchange = async () => {
			const file = input && input.files ? input.files[0] : null;
			const imgUrl = await uploadImage(file);
			quillObj.editor.insertEmbed(range.index, 'image', imgUrl);
			quillObj.setSelection(range.index + 1);
			onBlogContentChange(quillObj.getContents());
		};
		// }
	};

	const modules = useMemo(
		() => ({
			toolbar: {
				container: [
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					['bold', 'italic', 'underline', 'strike', 'blockquote'],
					[
						{ list: 'ordered' },
						{ list: 'bullet' },
						{ indent: '-1' },
						{ indent: '+1' },
					],
					['link', 'image'],
					['clean'],
					[
						{ color: ['#000000', '#e60000', '#9933ff', '#00ff00'] },
						{ background: [] },
					], // dropdown with defaults from theme
					[{ font: [] }],
					[{ align: [] }],
				],
				handlers: {
					image: imageHandler,
				},
			},
			clipboard: {
				// toggle to add extra line breaks when pasting HTML:
				matchVisual: false,
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
		'color',
		'font',
		'background',
		'clean',
		'align',
	];

	return (
		<ReactQuill
			ref={quillRef}
			style={{ height: '18.75rem' }}
			value={value}
			onChange={onBlogContentChange}
			modules={modules}
			formats={formats}
		/>
	);
};

export default RichTextEditor;

import {
	Button,
	Flex,
	Heading,
	Image,
	SimpleGrid,
	Text,
	Box,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import noService from '../../assets/service/noData.png';
import { DashboardContext } from '../../context/DashboardContext';
import { MainLayout, SidebarLayout } from '../../layouts';
import LatestFromUs from '../Dashboard/components/LatestFromUs';
import Stats from '../WebsiteProducts/components/Stats';
import blogs_logo from '../../assets/rightBar/blogs.png';
import ServiceCard from './components/ServiceCard';

const ServicesList = () => {
	const navigate = useNavigate();
	const params = useParams();
	const serviceCategoryId = params.serviceCategoryId;
	const { services, getServicsUnderCategory, servicesCount } =
		useContext(DashboardContext);
	useEffect(() => {
		getServicsUnderCategory(serviceCategoryId);
	}, []);

	const ServiceListMain = () => {
		return (
			<Flex flexDir={'column'} gap="3rem">
				<Box>
					<Text
						userSelect="none"
						cursor="pointer"
						onClick={() => navigate('/categoryservice-list')}>
						&larr; Back
					</Text>
				</Box>
				<Flex align={'start'} justify={'space-between'}>
					<Flex flexDir={'column'}>
						<Heading fontSize={'6xl'} fontWeight="extrabold">
							Listed Services
						</Heading>
						<Text fontWeight={'light'} color={'gray'}>
							Create new service here
						</Text>
					</Flex>
					<Link to={`/create-service/${serviceCategoryId}`}>
						<Button
							bgGradient="linear(to-r, #3a5ef0 , #0f2daa)"
							colorScheme="primary"
							size="md">
							Create New Service&nbsp;&nbsp;+
						</Button>
					</Link>
				</Flex>
				<Flex align={'center'}>
					<Text fontSize={'xl'} fontWeight="extrabold" mr="auto">
						All Services under this Category
					</Text>
				</Flex>
				{services.length < 1 && (
					<Flex>
						<Image w="80%" alt="no service image" src={noService} />
					</Flex>
				)}
				<SimpleGrid columns={2} spacing={10}>
					{services.length > 0 &&
						services.map((service, index) => (
							<div key={index}>
								<ServiceCard data={service} />
							</div>
						))}
				</SimpleGrid>
			</Flex>
		);
	};

	const ServiceListMainRightBar = () => {
		return (
			<Flex h="100%" justify={'space-evenly'} flexDir={'column'}>
				<Flex gap="1rem" flexDir={'column'}>
					<Heading fontSize={'2xl'} fontWeight="semibold">
						Stats
					</Heading>
					<Flex flexDir={'column'} gap={'1rem'} w="100%">
						<Stats
							image={blogs_logo}
							count={servicesCount}
							statsHeading="Available Services"
						/>
					</Flex>
				</Flex>
				<Flex gap="1rem" flexDir={'column'}>
					<LatestFromUs />
				</Flex>
			</Flex>
		);
	};

	return (
		<SidebarLayout>
			<MainLayout
				main={ServiceListMain()}
				rightBar={ServiceListMainRightBar()}
			/>
		</SidebarLayout>
	);
};

export default ServicesList;

import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/Auth.Context';
import { MainLayout, SidebarLayout } from '../../layouts';
import { getBlogById } from '../../services/requests';
import Moment from 'react-moment';
import LatestFromUs from '../Dashboard/components/LatestFromUs';
import GoBack from '../../shared/GoBack';
import { MdEdit } from 'react-icons/md';
import Stats from '../WebsiteProducts/components/Stats';
import total from '../../assets/rightBar/total.png';
import newReaders from '../../assets/rightBar/new.png';
import average from '../../assets/rightBar/average.png';

const ViewBlog = () => {
	const [blog, setBlog] = useState();
	const navigate = useNavigate();

	const { user } = useContext(AuthContext);

	const params = useParams();

	const openEditBlog = () => {
		navigate(`/edit-blog/${params.id}`);
	};

	useEffect(() => {
		getBlog().then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const ViewBlogMain = () => {
		return (
			<Flex flexDir={'column'} gap={6}>
				<Flex
					pos={'sticky'}
					top="0"
					p="0.5rem 0"
					bg="mainBg"
					align="center"
					justify={'space-between'}>
					<GoBack />
					<Button onClick={openEditBlog} colorScheme={'primary'}>
						Edit&nbsp;&nbsp;
						<MdEdit />
					</Button>
				</Flex>
				<Box>
					<Image
						borderRadius="20px"
						alt="blogview"
						src={blog?.blog_cover_image}
					/>
				</Box>
				<Flex gap={6} flexDir={'column'}>
					<Flex gap={2} color="grey">
						<Moment format="ll">{blog?.created_at}</Moment> •{' '}
						<Text>{blog?.author_name}</Text>
					</Flex>
					<Heading fontSize={'5xl'} fontWeight="bold">
						{blog?.blog_title}
					</Heading>
					<Text
						dangerouslySetInnerHTML={{
							__html: blog?.blog_content,
						}}
					/>
				</Flex>
			</Flex>
		);
	};

	const ViewBlogRightBar = () => {
		return (
			<Flex h="100%" justify={'inherit'} flexDir={'column'}>
				<Flex gap="1rem" flexDir={'column'}>
					<Flex gap="1rem" flexDir={'column'}>
						<Heading fontSize={'2xl'} fontWeight="semibold">
							Stats
						</Heading>
						<Flex flexDir={'column'} gap={'0.5rem'} w="100%">
							<Stats
								count={'234'}
								image={total}
								statsHeading="Total Readers"
							/>
							<Stats
								count={'17'}
								image={newReaders}
								statsHeading="New Readers"
							/>
							<Stats
								count={'65%'}
								image={average}
								statsHeading="Average Blog Completion"
							/>
						</Flex>
					</Flex>
				</Flex>
				<Flex gap="1rem" flexDir={'column'}>
					<LatestFromUs />
				</Flex>
			</Flex>
		);
	};

	const getBlog = async () => {
		const uniqueToken = user?.default_project?.unique_token;
		const id = params.id;
		const res = await getBlogById(uniqueToken, id);
		setBlog(res.doc);
	};

	return (
		<SidebarLayout>
			<MainLayout main={ViewBlogMain()} rightBar={ViewBlogRightBar()} />
		</SidebarLayout>
	);
};

export default ViewBlog;

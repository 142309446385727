import { Box, Flex, Heading, Input, OrderedList, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import ScheduledEmail from '../../components/ScheduledEmail';
import { MainLayout, SidebarLayout } from '../../layouts';
import { DashboardContext } from '../../context/DashboardContext';
import TopInfoCard from '../Blog/components/TopInfoCard';
import LatestFromUs from '../Dashboard/components/LatestFromUs';
import { AuthContext } from '../../context/Auth.Context';

const AutomatedEmails = () => {
	const {
		upsertEmailTemplate,
		emailTemplate0,
		emailTemplate1,
		emailTemplate2,
		emailTemplate3,
		emailTemplate4,
	} = useContext(DashboardContext);
	const { user } = useContext(AuthContext);

	const saveEmail = async (subject, html_content, day) => {
		const payload = {
			subject,
			html_content,
			day,
		};
		await upsertEmailTemplate(payload, day);
	};

	const AutomatedEmailsMain = () => {
		return (
			<Flex flexDir={'column'} gap="3rem">
				<Box>
					<Flex alignItems="center" justifyContent="space-between">
						<Heading fontSize={'6xl'} fontWeight="extrabold">
							Automated E-mails
						</Heading>
					</Flex>
					<Text fontWeight={'light'} color={'gray'}>
						create and save all your automated e-mails here.
					</Text>
				</Box>
				<Flex fontWeight={'bold'} gap="1rem" flexDir={'column'}>
					<Flex align={'center'}>
						<Text w="25%">Sender's Name</Text>
						<Input isDisabled value={user?.name} />
					</Flex>
					<Flex align={'center'}>
						<Text w="25%">Sender's Email</Text>
						<Input isDisabled value={user?.email} />
					</Flex>
				</Flex>
				<OrderedList>
					<Flex flexDir={'column'} gap="5rem">
						<ScheduledEmail
							heading="Confirmation Mail"
							onSave={saveEmail}
							day={0}
							template={emailTemplate0}
						/>
						<ScheduledEmail
							heading="Follow Up E-Mail 1"
							onSave={saveEmail}
							day={1}
							template={emailTemplate1}
						/>
						<ScheduledEmail
							heading="Follow Up E-Mail 2"
							onSave={saveEmail}
							day={2}
							template={emailTemplate2}
						/>
						<ScheduledEmail
							heading="Follow Up E-Mail 3"
							onSave={saveEmail}
							day={3}
							template={emailTemplate3}
						/>
						<ScheduledEmail
							heading="Follow Up E-Mail 4"
							onSave={saveEmail}
							day={4}
							template={emailTemplate4}
						/>
					</Flex>
				</OrderedList>
			</Flex>
		);
	};

	const AutomatedEmailsRightBar = () => {
		const topEmaildata = [
			{
				title: 'Top 5 E-mail tips',
				list: 'Insert a call-to-action;Use a crafty subject line;Have a clear focus for your content;Review, edit, and repeat;Write a Closure for Your Email',
			},
		];

		return (
			<Flex h="100%" justify={'space-evenly'} flexDir={'column'}>
				<Flex gap="1rem" flexDir={'column'}>
					<TopInfoCard data={topEmaildata} />
				</Flex>
				<Flex gap="1rem" flexDir={'column'}>
					<LatestFromUs />
				</Flex>
			</Flex>
		);
	};

	return (
		<SidebarLayout>
			<MainLayout
				main={AutomatedEmailsMain()}
				rightBar={AutomatedEmailsRightBar()}
			/>
		</SidebarLayout>
	);
};

export default AutomatedEmails;

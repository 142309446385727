import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Icon,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import { HiOutlineRefresh } from 'react-icons/hi';
import { MdSettings } from 'react-icons/md';
import PageSettingsBarForm from './element/PageSettingsBarForm';

const PageSettingsBar = () => {
	return (
		<Accordion allowMultiple>
			<AccordionItem
				borderRadius={'md'}
				shadow={'base'}
				bg="#f4f4f4"
				border={'0.5px solid lightgray'}>
				<h2>
					<AccordionButton
						p=" 0.5rem 1rem"
						_expanded={{ bg: '#3A5DEF', color: 'white' }}>
						<Icon mr="1rem" fontSize={'1.5rem'} as={MdSettings} />
						<Box
							display={'flex'}
							alignItems={'center'}
							fontWeight={'semibold'}
							fontSize={'3xl'}
							flex="1"
							textAlign="left">
							Page Settings
							<Text
								ml="1rem"
								color={'gray.200'}
								fontWeight={'normal'}
								fontSize={'md'}
								as={'span'}>
								(Click to view & edit)
							</Text>
						</Box>
						<Button
							mr="1rem"
							leftIcon={<HiOutlineRefresh />}
							bg={'transparent'}
							colorScheme={'gray'}
							border={'1px solid lightgray'}
							size="md">
							Update Settings
						</Button>
						<AccordionIcon />
					</AccordionButton>
				</h2>

				<AccordionPanel pb={4}>
					<PageSettingsBarForm />
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	);
};

export default PageSettingsBar;

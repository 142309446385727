import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import { HiDotsHorizontal } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import EditPagesCardLayout from '../../layouts/EditPagesCardLayout';

const EditPagesCard = ({ getData }) => {
	return (
		<>
			{getData.map((data, i) => (
				<EditPagesCardLayout>
					<Box p={'1rem'}>
						<Flex
							fontSize={'1rem'}
							align={'center'}
							justify={'space-between'}>
							<Box _hover={{ color: 'primary.500' }}>
								{data.from === 'website' ? (
									<Link to={`${data.number}`}>
										<FiEdit fontSize={'1.3rem'} />
									</Link>
								) : (
									<Link to={`${data.number}`}>
										<FiEdit fontSize={'1.3rem'} />
									</Link>
								)}
							</Box>
							<HiDotsHorizontal />
						</Flex>
						<Flex justify={'center'} align={'center'} h={'full'}>
							<Image
								borderRadius={'md'}
								objectFit={'cover'}
								src={data.img}
							/>
						</Flex>
					</Box>
					<Heading
						borderTop="1px solid lightgray"
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						size="md"
						fontWeight={'normal'}
						p="0.8rem 0">
						<Text>{data.name}</Text>
					</Heading>
				</EditPagesCardLayout>
			))}
		</>
	);
};

export default EditPagesCard;

import { Flex } from '@chakra-ui/react';
import React from 'react';
import { MainLayout, SidebarLayout } from '../../layouts';

const AnalyticsMain = () => {
	return (
		<Flex>
			<h1>AnalyticsMain</h1>
		</Flex>
	);
};

const AnalyticsRightBar = () => {
	return (
		<Flex>
			<h1>AnalyticsRightBar</h1>
		</Flex>
	);
};

const Analytics = () => {
	return (
		<SidebarLayout>
			<MainLayout
				main={<AnalyticsMain />}
				rightBar={<AnalyticsRightBar />}
			/>
		</SidebarLayout>
	);
};

export default Analytics;

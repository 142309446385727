import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Sidebar } from '../components';

const SidebarLayout = ({ children }) => {
	return (
		<Flex h="100vh">
			<Flex>
				<Box w="8vw" pos="fixed">
					<Sidebar />
				</Box>
			</Flex>
			<Flex w="100%" flexDir={'column'} ml="8vw">
				{children}
			</Flex>
		</Flex>
	);
};

export default SidebarLayout;

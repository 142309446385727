import {
	Flex,
	Heading,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { DashboardContext } from '../../context/DashboardContext';
import { MainLayout, SidebarLayout } from '../../layouts';
import moment from 'moment';
import LatestFromUs from '../Dashboard/components/LatestFromUs';
import Stats from '../WebsiteProducts/components/Stats';
import totalBookings from '../../assets/rightBar/totalBookings.png';
import newBookings from '../../assets/rightBar/newBookings.png';
import cancellation from '../../assets/rightBar/cancellation.png';

const BookingsMain = () => {
	const { bookings } = useContext(DashboardContext);

	return (
		<Flex flexDir={'column'} gap="3rem">
			<Flex align={'start'} justify={'space-between'}>
				<Flex flexDir={'column'}>
					<Heading fontSize={'6xl'} fontWeight="extrabold">
						Your Bookings
					</Heading>
				</Flex>
			</Flex>
			<TableContainer>
				<Table variant="simple">
					<Thead>
						<Tr>
							<Th>S. no</Th>
							<Th>Name</Th>
							<Th>Email</Th>
							<Th>Event Date</Th>
							<Th>Contact Number</Th>
							<Th>Product</Th>
						</Tr>
					</Thead>
					<Tbody>
						{bookings?.map(
							(
								{
									user_name,
									user_email,
									user_contact_number,
									product,
									event_date,
									_id,
								},
								i,
							) => (
								<Tr
									key={_id}
									whiteSpace={'pre-wrap'}
									fontSize={'sm'}>
									<Td>{i + 1}</Td>
									<Td>{user_name}</Td>
									<Td>{user_email}</Td>
									<Td>{moment(event_date).format('ll')}</Td>
									<Td>{user_contact_number}</Td>
									<Td>{product.name}</Td>
								</Tr>
							),
						)}
					</Tbody>
				</Table>
			</TableContainer>
		</Flex>
	);
};

const BookingsRightBar = () => {
	return (
		<Flex h="100%" justify={'space-evenly'} flexDir={'column'}>
			<Flex gap="1rem" flexDir={'column'}>
				<Heading fontSize={'2xl'} fontWeight="semibold">
					Stats
				</Heading>
				<Flex flexDir={'column'} gap={'0.5rem'} w="100%">
					<Stats
						count={'80'}
						image={totalBookings}
						statsHeading="Total Bookings"
					/>
					<Stats
						count={'10'}
						image={newBookings}
						statsHeading="New Bookings"
					/>
					<Stats
						count={'4'}
						image={cancellation}
						statsHeading="Cancellations"
					/>
				</Flex>
			</Flex>
			<Flex gap="1rem" flexDir={'column'}>
				<LatestFromUs />
			</Flex>
		</Flex>
	);
};

const Bookings = () => {
	return (
		<SidebarLayout>
			<MainLayout
				main={<BookingsMain />}
				rightBar={<BookingsRightBar />}
			/>
		</SidebarLayout>
	);
};

export default Bookings;

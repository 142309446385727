import {
	Box,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Select,
	Textarea,
} from '@chakra-ui/react';
import React from 'react';

const PageSettingsBarForm = () => {
	return (
		<FormControl pl="2.5rem" pb="1rem">
			<Flex gap={'1rem'} flexDir={'column'}>
				<Box>
					<FormLabel htmlFor="email">Page Name</FormLabel>
					<Input
						id="email"
						type="email"
						placeholder=" Home page "
						bg="#ffffff"
					/>
				</Box>
				<Box>
					<FormLabel htmlFor="email">Meta-Title (SEO)</FormLabel>
					<Input
						id="email"
						type="email"
						placeholder=" Home page "
						bg="#ffffff"
					/>
				</Box>
				<Box>
					<FormLabel htmlFor="email">
						Meta-Description (SEO)
					</FormLabel>
					<Textarea placeholder=" Home page " bg="#ffffff" />{' '}
				</Box>
				<Box>
					<FormLabel htmlFor="email">Page URL</FormLabel>
					<Input
						id="email"
						type="email"
						placeholder=" Home page "
						bg="#ffffff"
					/>
				</Box>
				<Flex w="full" gap={'3rem'}>
					<Box w="full">
						<FormLabel htmlFor="email">Parent Page</FormLabel>

						<Select placeholder="Select option" bg="#ffffff">
							<option value="option1">Option 1</option>
							<option value="option2">Option 2</option>
							<option value="option3">Option 3</option>
						</Select>
					</Box>

					<Box w="full">
						<FormLabel htmlFor="email">Author</FormLabel>
						<Select placeholder="Select option" bg="#ffffff">
							<option value="option1">Option 1</option>
							<option value="option2">Option 2</option>
							<option value="option3">Option 3</option>
						</Select>
					</Box>
				</Flex>
			</Flex>
		</FormControl>
	);
};

export default PageSettingsBarForm;

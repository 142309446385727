import { useToast } from '@chakra-ui/react';
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	createProject,
	googleSignUp,
	me,
	signin,
	updateProject,
	updateUser,
} from '../services/requests';

export const AuthContext = createContext({
	isAuthenticated: false,
	userLoaded: false,
	userLoading: false,
	user: null,
	currentProject: null,
	loginWithEmailAndPassword: undefined,
	loginWithGoogle: undefined,
	logout: undefined,
	updateOrganizationAndProject: undefined,
	updateCurrentProject: undefined,
	updateMe: undefined,
});

export const AuthProvider = ({ children }) => {
	const navigate = useNavigate();
	const toast = useToast();

	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [userLoaded, setUserLoaded] = useState(false);
	const [userLoading, setUserLoading] = useState(false);
	const [user, setUser] = useState(null);
	const [currentProject, setCurrentProject] = useState(null);

	useEffect(() => {
		autoLogin().then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loginWithEmailAndPassword = async (payload) => {
		setUserLoading(true);
		signin(payload)
			.then((res) => {
				const token = res.token;
				console.log(res);
				if (!token) {
					toast({
						title: `${res.data.message}`,
						status: 'error',
						isClosable: true,
					});
				} else {
					localStorage.setItem('token', res.token);
					setUser(res?.data);
					setCurrentProject(res.data?.default_project);
					setIsAuthenticated(true);
					setUserLoaded(true);
					navigate('/dashboard');
				}
			})
			.finally(() => setUserLoading(false));
	};

	const autoLogin = async () => {
		const token = !!localStorage.getItem('token');
		if (token) {
			me().then((res) => {
				if (res.status === 200) {
					console.log(res);
					setUser(res.data?.data);
					setCurrentProject(res.data?.data?.default_project);
					setIsAuthenticated(true);
					setUserLoaded(true);
					setUserLoading(false);
					navigate('/dashboard');
				} else {
					logout(true).then();
				}
			});
		} else {
			logout(true).then();
		}
	};

	const loginWithGoogle = async (code) => {
		const res = await googleSignUp(code);
		console.log(res);
		if (res?.token) {
			await setUser(res?.data);
			await setIsAuthenticated(true);
			await setUserLoading(false);
			await setUserLoaded(true);
			await setCurrentProject(res?.data?.default_project);
			localStorage.setItem('token', res.token);
			await navigate('/dashboard');
		} else {
			toast({
				title: `${res.data.message}`,
				status: 'error',
				isClosable: true,
			});
		}
	};

	const logout = async (redirectToLogin = false) => {
		setIsAuthenticated(false);
		localStorage.removeItem('token');
		if (redirectToLogin) {
			navigate('/');
		}
	};

	const updateMe = async (updates) => {
		setUserLoading(true);
		const res = await updateUser({ ...updates });
		if (res?.data?.data) {
			await setUser(res.data.data);
			toast({
				title: `Profile Updated Successfully`,
				status: 'success',
				isClosable: true,
			});
		} else {
			toast({
				title: `Profile Updation Failed`,
				status: 'error',
				isClosable: true,
			});
		}
		setUserLoading(false);
		return res;
	};

	const updateCurrentProject = async (updates) => {
		setUserLoading(true);
		const res = await updateProject(currentProject._id, updates);
		if (res?.data?.data) {
			await setCurrentProject(res.data.data);
			toast({
				title: `Project Updated Successfully`,
				status: 'success',
				isClosable: true,
			});
		} else {
			toast({
				title: `Project Updation Failed`,
				status: 'error',
				isClosable: true,
			});
		}
		setUserLoading(false);
		return res;
	};

	const updateOrganizationAndProject = async (organization, project) => {
		setUserLoading(true);
		const res1$ = updateMe({ organization: organization });
		let res2$ = new Promise((resolve) => resolve());
		if (!currentProject) {
			res2$ = createProject({ name: project });
		}

		const res = await Promise.allSettled([res1$, res2$]);
		console.log(res);
		await setUser(res[0]?.value?.data?.data);
		if (!currentProject) {
			await setCurrentProject(res[1]?.value?.data?.data);
		}
		await setUserLoading(false);
	};

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				userLoaded,
				userLoading,
				currentProject,
				user,
				loginWithEmailAndPassword,
				loginWithGoogle,
				logout,
				updateOrganizationAndProject,
				updateCurrentProject,
				updateMe,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;

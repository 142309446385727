import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { BiPlusCircle } from 'react-icons/bi';
import {
	MetaDataBar,
	PageSettingsBar,
	SectionAccordion,
} from '../../components';
import { getPageSections } from '../../content/dummy';
import { MainLayout, SidebarLayout } from '../../layouts';
import GoBack from '../../shared/GoBack';

const PagesMain = () => {
	return (
		<>
			<Box mb="3rem">
				<Flex>
					<Box p="0.5rem 1rem 1rem 0rem">
						<GoBack />
					</Box>
					<Box>
						<Text fontWeight={'semibold'} fontSize={'5xl'}>
							Niwi's website &gt;{' '}
							<Text as={'span'} fontWeight={'light'}>
								Page Name
							</Text>
						</Text>
						<Text color={'gray.200'} fontSize={'md'}>
							Custom UI - Designed in collaboration with{' '}
							<Link
								color={'primary.500'}
								href="https://Apric.in"
								isExternal>
								Apric
							</Link>{' '}
						</Text>
					</Box>
				</Flex>
			</Box>
			<Box mb="2rem">
				<MetaDataBar />
			</Box>
			<Box mb="2rem">
				<PageSettingsBar />
			</Box>
			<Box>
				<Text fontWeight={'semibold'} fontSize={'4xl'}>
					Sections
				</Text>
				<Text color={'gray.200'} fontSize={'sm'}>
					(Click to view & edit Projects)
				</Text>
			</Box>
			<Box my="1rem" w="full">
				<Flex flexDir={'column'} gap={'1.2rem'}>
					<Button
						leftIcon={<BiPlusCircle fontSize={'2rem'} />}
						w="full"
						justifyContent={'start'}
						colorScheme="primary"
						size="lg"
						variant="outline">
						Add new Section
					</Button>
					{getPageSections.map((data, i) => (
						<SectionAccordion data={data} />
					))}
				</Flex>
			</Box>
		</>
	);
};

const PagesRightBar = () => {
	return (
		<Flex>
			<h1>PagesRightBar</h1>
		</Flex>
	);
};

const PageSection = () => {
	return (
		<SidebarLayout>
			<MainLayout main={<PagesMain />} rightBar={<PagesRightBar />} />
		</SidebarLayout>
	);
};

export default PageSection;
